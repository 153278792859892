import * as React from 'react';
import {Box, Card, CircularProgress, Divider, Grid, List, ListItem, Typography} from "@mui/material";
import {toTitleCase} from "../utils/StringUtils";
import {getFormattedDate} from "../utils/DateUtils";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";

const MessageList= (props: any) => {
    const [listItems, setListItems] = useState<any>([]);

    function getFormatString(formatValue: any) {
        const formatStrings: any = {
            "EMAIL": "Email",
            "LI_CONNECTION_REQUEST": 'Connection Request',
            'LI_MESSAGE': 'LinkedIn Message',
            'SMS': 'Text Message'
        }
        return formatStrings[formatValue]
    }

    useEffect(() => {
        let tmp: any = []
        props.messages.forEach((message: any, index: number) => {
            tmp.push(
                <Link to={`/contacts/${props.contact.id}/message`} key={index} state={{message: message}} style={{textDecoration: 'inherit', color: 'inherit'}}>
                    <ListItem key={index + 1}>
                        <Grid container>
                            <Grid xs={9} item>
                                <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                                    <Typography sx={{fontWeight: 'bold'}}>{getFormatString(message.message_format)}</Typography>
                                    <Typography>&nbsp;from&nbsp;</Typography>
                                    <Typography>{message.user.first_name} {message.user.last_name}</Typography>
                                </Box>
                            </Grid>
                            <Grid xs={3} item>
                                <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                    <Typography>{getFormattedDate(message.date_generated)}</Typography>
                                    <Typography sx={{fontWeight: 'bold', marginLeft: 1}}>v{message.version}</Typography>
                                </Box>
                            </Grid>
                            {message.summary}
                        </Grid>
                    </ListItem>
                </Link>
            );
            tmp.push(
                <Divider key={`d${index}`}/>
            );
        })
        setListItems(tmp);
    }, [props.messages])

    return (
        <Card variant={'outlined'} sx={{height: 'inherit', width: '100%'}} ref={props?.refMessageList}>
            {!props.loading &&
                <List sx={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>
                    <ListItem key={0} style={{marginBottom: '8px'}}>
                        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <Link
                                to={`/contacts/${props.contact.id}/new`}
                                state={{contact: props.contact}}
                            >
                                <Button ref={props?.refNewMessageButton} icon={<PlusOutlined/>} type={'primary'} block>New Message</Button>
                            </Link>
                        </Box>
                    </ListItem>
                    <Divider />
                    {listItems}
                </List>
            }
            {props.loading &&
                <Box sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 2
                }}>
                    <CircularProgress/>
                </Box>
            }
        </Card>
    );
};

export default MessageList;
