import {
    Box, Button,
    Card,
    CardContent, CircularProgress, Dialog, DialogContent, DialogTitle,
    FormControl, FormControlLabel, FormGroup,
    Grid, IconButton,
    InputLabel,
    MenuItem, OutlinedInput,
    Select,
    Slider, Switch, TextField,
    Typography
} from "@mui/material";
import {useLocation} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {useEffect, useRef, useState} from "react";
import MessageVersionList from "../../common/MessageVersionList";
import {MessageService} from "../../services/MessageService";
import Snackbar from '@mui/material/Snackbar';
import MessageComponent from "./MessageComponent";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import * as React from "react";
import {Input, Modal, Radio, Tour, TourProps} from "antd";
import {Col, Divider, List, Row, Skeleton, Space} from "antd/lib";
import SaveTemplateModal from "../../common/SaveTemplateModal";
import config from "../../config.json";

const MessageScreen = (props: any) => {
    const location = useLocation();
    const {state} = location
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
    const [feedback, setFeedback] = useState('');
    const [message, setMessage] = useState(state.message);
    const [loading, setLoading] = useState(false);
    const [messageVersions, setMessageVersions] = useState<any>([]);
    const [messageVersionsHeight, setMessageVersionsHeight] = useState(400);
    const [loadingMessageVersions, setLoadingMessageVersions] = useState(true);
    const [chosenMessageVersionIndex, setChosenMessageVersionIndex] = useState<number>(0)
    const [segmentFeedbacks, setSegmentFeedbacks] = useState<any>([])
    const [segments, setSegments] = useState<any>([])
    const inputRef = useRef<any>(null);
    const messageRef = useRef<any>();
    const didMount = React.useRef(false);
    const [dialogOpen, setDialogOpen] = useState<any>(false)

    // These are for demo
    const {demo} = config
    const refMessage = useRef(null);
    const refMetrics = useRef(null);
    const refTemplate = useRef(null);
    const refCopy = useRef(null);
    const refRevisions = useRef(null);
    const refVersions = useRef(null);
    const refReviseButton = useRef(null);
    const [openTour, setOpenTour] = useState(demo)
    const tourSteps: TourProps['steps'] = [
        {
            title: 'Here\'s Your Message!',
            description: 'Use the tools on this page to polish your generated message before sending it off.',
            target: null
        },
        {
            title: 'Prune & Tweak Your Message',
            description: 'Your generated message can continued to be altered by you if you choose. Click on a part of the message to edit inline directly, or triple-click to provide segment specific feedback.',
            target: () => refMessage.current,
            placement: 'top'
        },
        {
            title: 'Message Metrics',
            description: 'View quick metrics about your message, including word count, character count, and estimated read time.',
            target: () => refMetrics.current,
            placement: 'bottom'
        },
        {
            title: 'Request Revisions',
            description: 'Provide feedback for regenerating the whole message here. We\'ll recreate your message with this feedback in mind!',
            target: () => refRevisions.current,
            placement: 'top'
        },
        {
            title: 'Regenerate Message',
            description: 'Click this button to regenerate your message with your newly provided feedback in mind.',
            target: () => refReviseButton.current,
            placement: 'left'
        },
        {
            title: 'Template',
            description: 'If you have a message you think would appeal to other recipients, you can create a template out of it to use when generating subsequent messages.',
            target: () => refTemplate.current,
            placement: 'bottom'
        },
        {
            title: 'Copy',
            description: 'Once you have your message exactly how you want it, copy it to your clipboard by clicking this icon, and then paste it where needed.',
            target: () => refCopy.current,
            placement: 'bottom'
        },
        {
            title: 'Versions',
            description: 'Your message versions are stored here. Click through them to compare and contrast.',
            target: () => refVersions.current,
            placement: 'right'
        }
    ]

    useEffect(() => {
        if (inputRef && inputRef.current) {
            const height = inputRef.current.offsetHeight;
            const minHeight = 400
            setMessageVersionsHeight(height < minHeight ? minHeight : height)
        }
        if (messageVersions.length == 0) {
            getMessageVersions();
        }
    }, [inputRef?.current?.offsetHeight, message]);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
        setLoading(false);
    };

    const onTemplateCreated = (templateName: string) => {
        toast(<div><b>Template Created</b><br />{templateName}</div>, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
    }

    const onTemplateUpdated = (templateName: string) => {
        toast(<div><b>Template Updated</b><br />{templateName}</div>, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
    }

    function handleSegmentFeedbacksChange(feedbacks: any) {
        console.log(`Changed segment feedbacks: ${feedbacks.length}`)
        setSegmentFeedbacks(feedbacks)
    }

    function handleSegmentsChange(segments: any) {
        console.log(`Changed segments: ${segments.length}`)
        setSegments(segments)
    }

    function getMessageVersions() {
        MessageService.getMessageVersions(message.id, setLoadingMessageVersions, props.user).subscribe((data: any) => {
            console.log(`Received ${data.length} message versions`)
            setMessageVersions(data);
        }, (error: any) => {
            console.error(`Error getting message versions: ${error}`)
            setSnackbarOpen(true)
        });
    }

    function messageVersionClicked(message: any) {
        console.log(`Clicked version ${message.version}`)
        setMessage(message)
    }

    const handleFeedbackChange = (event: any) => {
        setFeedback(event.target.value)
    }

    function copyMessage() {
        toast("Message Copied!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
    }


    function runFeedback() {
        setLoading(true);
        setLoadingMessageVersions(true)
        let fullFeedback = ''
        if (feedback.length > 0) {
            fullFeedback = `GENERAL FEEDBACK:\n${feedback}`
        }
        if (segmentFeedbacks.length > 0) {
            if (fullFeedback.length > 0) {
                fullFeedback += '\n'
            }
            fullFeedback += 'MESSAGE PART SPECIFIC FEEDBACK:\n'
            segmentFeedbacks.forEach((segFeedback: any) => {
                fullFeedback += `- Referring to message part "${segFeedback['segment']}": ${segFeedback['feedback']}\n`
            })
        }

        console.log(`Full Feedback: ${fullFeedback}`);
        const payload = {
            'message': message,
            'feedback': fullFeedback
        }
        console.log(`Payload: ${JSON.stringify(payload)}`)
        MessageService.addFeedback(JSON.stringify(payload), setLoading, props.user).subscribe((data: any) => {
            console.log(`Message: ${JSON.stringify(data)}`)
            window.heap.track('Message Revision', data);
            setMessage(data)
            setFeedback('');
            setSegmentFeedbacks([])
            messageRef.current.clearSegmentFeedbacks()
            messageVersions.splice(0, 0, data)
            setMessageVersions(messageVersions)
            setChosenMessageVersionIndex(0)
            setLoadingMessageVersions(false);
        }, (error: any) => {
            console.error(`Error fetching message with feedback: ${error}`)
            setSnackbarOpen(true)
            setLoadingMessageVersions(false)
        });
    }

    function completeDraft() {
        setLoading(true);
        setLoadingMessageVersions(true)
        const newBodyStr: string = segments.join('')
        const payload = {
            'message': message,
            'new_body': newBodyStr
        }
        MessageService.completeDraft(JSON.stringify(payload), setLoading, props.user).subscribe((data: any) => {
            console.log(`Message: ${JSON.stringify(data)}`)
            window.heap.track('Message Revision', data);
            setMessage(data)
            setFeedback('');
            setSegmentFeedbacks([])
            messageRef.current.clearSegmentFeedbacks()
            messageVersions.splice(0, 0, data)
            setMessageVersions(messageVersions)
            setChosenMessageVersionIndex(0)
            setLoadingMessageVersions(false)
        }, (error: any) => {
            console.error(`Error completing message draft: ${error}`)
            setSnackbarOpen(true)
            setLoadingMessageVersions(false)
        });
    }

    function createTemplate() {
        setDialogOpen(true)
    }

    return (
        <div>
            <Card>
                <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '800'}}>
                    <CardContent sx={{flex: '1 0 auto'}}>
                        <Grid container spacing={1} sx={{paddingTop: 5, paddingLeft: 2, paddingRight: 2}}>
                            <Grid xs={4}>
                                <Box sx={{height: messageVersionsHeight}} ref={refVersions}>
                                    <MessageVersionList messages={messageVersions} loading={loadingMessageVersions}
                                                        onClick={messageVersionClicked}
                                                        chosenIndex={chosenMessageVersionIndex}/>
                                </Box>
                            </Grid>
                            <Grid xs={8} ref={inputRef}>
                                <Box sx={{display: 'flex', height: 'auto', marginLeft: 3}}>
                                    <MessageComponent message={message.message_body}
                                                      onSegmentFeedbacksChange={(feedbacks: any) => handleSegmentFeedbacksChange(feedbacks)}
                                                      onSegmentsChange={(segments: any) => handleSegmentsChange(segments)}
                                                      ref={messageRef}
                                                      copyMessage={copyMessage}
                                                      completeDraft={completeDraft}
                                                      createTemplate={createTemplate}
                                                      loading={loading}
                                                      refMessage={refMessage}
                                                      refMetrics={refMetrics}
                                                      refTemplate={refTemplate}
                                                      refCopy={refCopy}

                                    />
                                </Box>
                                <Box sx={{display: 'flex', height: 'auto', marginTop: 2, marginLeft: 3}}>
                                    <div ref={refRevisions} style={{width: '100%'}}>
                                        <Input.TextArea
                                            placeholder="Type your general revisions here."
                                            value={feedback}
                                            onChange={handleFeedbackChange}
                                            style={{width: "100%"}}
                                        />
                                    </div>
                                </Box>
                            </Grid>
                            <Grid xs={12} sx={{marginTop: 2}}>
                                    <LoadingButton size={'small'} variant={'contained'}
                                                   sx={{float: 'right'}} onClick={runFeedback}
                                                   loading={loading}
                                                   ref={refReviseButton}
                                                   disabled={(feedback.length == 0 &&  segmentFeedbacks.length == 0)}
                                    >
                                        Revise Message
                                    </LoadingButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Box>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Service Error - Please Try Again Soon"
                />
            </Card>
            <Box>
                <ToastContainer/>
            </Box>
            <SaveTemplateModal open={dialogOpen} setOpen={setDialogOpen} message={message} user={props.user}
                onTemplateCreated={onTemplateCreated} onTemplateUpdated={onTemplateUpdated}/>
            <Tour open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps} />
        </div>
    );
}
export default MessageScreen;