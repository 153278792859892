import React from 'react';
import {Button, Card, Col, Row, Typography} from 'antd';
import {Link} from 'react-router-dom';
import {Collapse} from "antd/lib";
import {ChevronRightOutlined} from "@mui/icons-material";
import config from "../../config.json";

const {Title} = Typography;

const HomeScreen = (props: any) => {
    const { demo } = config
    const textColorOptions: any = {
        'COMPLETED': 'limegreen',
        'IN PROGRESS': 'orange'
    }

    function getTextColor(val: string) {
        return textColorOptions[val]
    }
    return (
        <Card style={{height: 800}}>
            <Row>
                <Col span={24} style={{textAlign: 'right'}}>
                    <Link to={'/contacts'} style={{color: 'inherit', textDecoration: 'none'}}>
                        <Button type="primary" ghost>Contacts</Button>
                    </Link>
                </Col>
                <Col span={24}>
                    <Title level={2} style={{display: 'inline', marginRight: 5}}>Welcome back,</Title>
                    <Title level={2} style={{display: 'inline', fontWeight: 'bold'}}>{props.user.first_name}</Title>
                    {demo && <Title level={5} style={{display: 'inline', marginRight: 5}}>&nbsp;(Financial Advisor, Edward Jones)</Title>}
                </Col>
            </Row>
            {demo &&
                <Row style={{marginTop: '24px'}}>
                    <Link to={'/contacts/new'} style={{color: 'inherit', textDecoration: 'none'}}>
                        <Button type="primary" size={"large"}>
                            Let's Get Started With Your First Message
                        </Button>
                    </Link>
                </Row>
            }
            <Row style={{marginTop: '48px'}}>
                <Col span={24}>
                    <Title level={5} style={{display: 'inline', marginRight: 5}}>You're just a few steps away from
                        getting the most out of Conscribe!</Title>
                </Col>
            </Row>
            <Row style={{marginTop: '24px'}}>
                <Collapse size="middle" style={{width: '100%'}}>
                    <Collapse.Panel header="Connect Your Google Calendar" key="1" extra={<Typography.Text strong style={{color: 'orange'}}>IN PROGRESS</Typography.Text>}>
                        <Typography.Paragraph>
                            Connect your Google calendar with Conscribe so you can:<br/>
                            <ul>
                                <li>Automatically suggest meeting times</li>
                                <li>Embed meeting reminders in your messages</li>
                                <li>Respond to meeting invites with your schedule in mind</li>
                            </ul>
                        </Typography.Paragraph>
                        <Button type="primary" disabled={true}>Coming Soon</Button>
                    </Collapse.Panel>
                </Collapse>
            </Row>
            <Row style={{marginTop: '24px'}}>
                <Collapse size="middle" style={{width: '100%'}}>
                    <Collapse.Panel header="Connect Your Gmail Account" key="2" extra={<Typography.Text strong style={{color: 'orange'}}>IN PROGRESS</Typography.Text>}>
                        <Typography.Paragraph>
                            Connect your Google Mail account with Conscribe to:<br/>
                            <ul>
                                <li>Generate automatic responses to inbound emails from your contacts</li>
                                <li>Produce more context-rich communications</li>
                            </ul>
                        </Typography.Paragraph>
                        <Button type="primary" disabled={true}>Coming Soon</Button>
                    </Collapse.Panel>
                </Collapse>
            </Row>
            <Row style={{marginTop: '24px'}}>
                <Collapse size="middle" style={{width: '100%'}}>
                    <Collapse.Panel header="Confirm Your Org Details" key="3" extra={<Typography.Text strong style={{color: getTextColor((props.user.company && props.user.company?.status == 'ACTIVE') ? 'COMPLETED' : 'NOT COMPLETED')}}>{(props.user.company && props.user.company?.status == 'ACTIVE') ? 'COMPLETED' : 'NOT COMPLETED'}</Typography.Text>}>
                        <Typography.Paragraph>
                            Confirm your company details to start utilizing Conscribe company and team features for
                            maximum productivity.
                        </Typography.Paragraph>
                        <Link to={'/teams'} style={{color: 'inherit', textDecoration: 'none'}}>
                            <Button type="primary">Confirm Company Details</Button>
                        </Link>
                    </Collapse.Panel>
                </Collapse>
            </Row>
            <Row style={{marginTop: '24px'}}>
                <Collapse size="middle" style={{width: '100%'}}>
                    <Collapse.Panel header="Assign Your Teams" key="4" extra={<Typography.Text strong style={{color: getTextColor((props.user.company && props.user.company?.status == 'ACTIVE') ? 'COMPLETED' : 'NOT COMPLETED')}}>{(props.user.company && props.user.company?.status == 'ACTIVE') ? 'IN PROGRESS' : 'NOT COMPLETED'}</Typography.Text>}>
                        <Typography.Paragraph>
                            Once you have your company details confirmed, you can utilize teams to:
                            <ul>
                                <li>Share contacts</li>
                                <li>Request message reviews</li>
                                <li>Include additional team members as conversational context</li>
                            </ul>
                        </Typography.Paragraph>
                        <Link to={'/teams'} style={{color: 'inherit', textDecoration: 'none'}}>
                            <Button type="primary">Assign Teams</Button>
                        </Link>
                    </Collapse.Panel>
                </Collapse>
            </Row>
        </Card>
    );
};

export default HomeScreen;