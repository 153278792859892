import GeneralService from "./GeneralService";
import { Component } from "react";

export class ContextService extends Component {

    public static getContexts(entity_type: string, entity_id: number, context_type: string, loadingHandler: any, user: any) {
        return GeneralService.get(`/context/${entity_type}/${entity_id}/${context_type}`, loadingHandler, JSON, user)
    }

    public static addContext(body: string, loadingHandler: any, user: any) {
        return GeneralService.post('/context/new', body, loadingHandler, JSON, user);
    }

    public static deleteContext(context_id: number, loadingHandler: any, user: any) {
        return GeneralService.delete(`/context/${context_id}`, loadingHandler, JSON, user);
    }

}