import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import {
    Avatar,
    Button,
    Card,
    Col,
    Input,
    Menu,
    Row,
    Space,
    Typography,
    Dropdown, TourProps, Tour,
} from "antd";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { getFormattedDate } from "../../utils/DateUtils";
import MessageList from "../../common/MessageList";
import { ContactService } from "../../services/ContactService";
import { MessageService } from "../../services/MessageService";
import ContextCoverageList from "../../common/ContextCoverageList";
import TagsComponent from "../../common/TagsComponent";
import {deepOrange} from "@mui/material/colors";
import config from "../../config.json";

const {Item: MenuItem} = Menu;


const ContactScreen = (props: any) => {
    const location = useLocation();
    const didMount = React.useRef(false);
    const {state} = location;
    const {id} = useParams();
    const [messages, setMessages] = useState<any>([]);
    const [loadingMessages, setLoadingMessages] = useState(true);
    const [contact, setContact] = useState<any>(state ? state.contact : null);
    const [rightColHeight, setRightColHeight] = useState(0);
    const inputRef = useRef<any>(null);

    // These are for demo
    const {demo} = config
    const refContactInfo = useRef(null);
    const refContextCoverage = useRef(null);
    const refMessageList = useRef(null);
    const refNewMessageButton = useRef(null);
    const [openTour, setOpenTour] = useState(demo)
    const tourSteps: TourProps['steps'] = [
        {
            title: 'Contact Imported!',
            description: 'Here you\'ll see information related to your contact, and exposure to the ability to create and revisit Conscribe messages!',
            target: null
        },
        {
            title: 'Contact Info',
            description: 'View quick information about your contact.',
            target: () => refContactInfo.current,
            placement: 'bottom'
        },
        {
            title: 'Context Coverage',
            description: 'Understand how much information was found about your contact. The more information Conscribe is able to pull together, the more effective the messaging will be!',
            target: () => refContextCoverage.current,
            placement: 'top'
        },
        {
            title: 'Message List',
            description: 'This is where a list of your generated messages for this contact will sit. Only the summary will be shown, but you can click on a list item to see the full message.',
            target: () => refMessageList.current,
            placement: 'left'
        },
        {
            title: 'New Message',
            description: 'You can create new messages for this contact by clicking this button.',
            target: () => refNewMessageButton.current,
            placement: 'bottom'
        }
    ]

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            if (!contact && id) {
                ContactService.getContact(+id, null, props.user).subscribe(
                    (data: any) => {
                        setContact(data);
                    },
                    (error: any) => {
                        console.error(`Error fetching contact: ${error}`);
                    }
                );
            }
        }
        if (inputRef && inputRef.current) {
            setRightColHeight(inputRef.current.offsetHeight);
        }
        if (contact) {
            getMessages();
        }
    }, [state, contact, inputRef]);

    function getMessages() {
        MessageService.getMessagesByContact(contact.id, setLoadingMessages, props.user).subscribe(
            (data: any) => {
                setMessages(data);
            },
            (error: any) => {
                console.error(`Error getting messages: ${error}`);
            }
        );
    }

    function handleMenuClose() {

    }

    const menu = (
        <Menu onClick={handleMenuClose}>
            <MenuItem key="1">Share</MenuItem>
            <MenuItem key="2">Delete</MenuItem>
        </Menu>
    );

    return (
        <Card>
            {contact &&
                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "800" }}>
                    <div style={{ flex: "1 0 auto", padding: "16px" }}>
                        <Row gutter={[16, 16]}>
                            <Col xs={12}>
                                <div ref={refContactInfo}>
                                    <Row align="middle">
                                        <Col span={3}>
                                            {contact.profile_pic && (
                                                <Avatar size={52} src={contact.profile_pic}></Avatar>
                                            )}
                                            {!contact.profile_pic && (
                                                <Avatar size={52}
                                                        style={{
                                                            backgroundColor: deepOrange[500],
                                                        }}
                                                >
                                                    {contact.first_name.charAt(0)}
                                                </Avatar>
                                            )}
                                        </Col>
                                        <Col span={21}>
                                            <Row>
                                                <Col span={12}>
                                                    <Typography.Title level={4} style={{ fontWeight: "bold", marginRight: "8px", marginBottom: 0, marginTop: 0}}>
                                                        {contact.first_name} {contact.last_name}
                                                    </Typography.Title>
                                                </Col>
                                                <Col span={12}>
                                                    <Typography.Text style={{ float: "right" }}>
                                                        Added {getFormattedDate(contact.date_added)}
                                                    </Typography.Text>
                                                </Col>
                                                <Col span={24}>
                                                    <Typography.Text style={{ marginRight: "8px" }}>{contact.company?.company_name ?? 'No Company'}</Typography.Text>
                                                </Col>
                                                <Col span={24}>
                                                    <TagsComponent
                                                        entityType={"CONTACT"}
                                                        entityId={contact.id}
                                                        tagType={"ROLE"}
                                                        user={props.user}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Typography.Paragraph style={{ marginTop: "16px" }}>
                                                {contact.summary}
                                            </Typography.Paragraph>
                                            <div style={{marginTop: 16}}>
                                                <Input
                                                    addonBefore="https://www.linkedin.com/in/"
                                                    value={contact.linkedin_id}
                                                    readOnly
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col xs={24}>
                                        <div style={{ marginTop: "16px" }} ref={refContextCoverage}>
                                            <ContextCoverageList />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12}>
                                <Row justify="end">
                                    <Dropdown
                                        overlay={menu}
                                        trigger={['click']}
                                    >
                                        <Button
                                            icon={<MoreOutlined/>}
                                            onClick={(e) => e.preventDefault()}
                                            style={{marginLeft: 8}}
                                        />
                                    </Dropdown>
                                </Row>
                                <Row style={{marginTop: '8px', paddingBottom: '40px', height: '100%', width: '100%'}}>
                                    <MessageList
                                        refMessageList={refMessageList}
                                        refNewMessageButton={refNewMessageButton}
                                        messages={messages}
                                        loading={loadingMessages}
                                        contact={contact}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            }
            <Tour open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps} />
        </Card>
    )
};
export default ContactScreen;
