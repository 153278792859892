import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
    Card,
    Col,
    Row,
    Slider,
    Switch,
    Select,
    Input,
    InputNumber,
    Button,
    Typography,
    Form,
    Tooltip,
    TourProps, Tour, Spin
} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import IntentList from "../../common/IntentList";
import {ChatGPTService} from "../../services/ChatGPTService";
import "./NewCommScreen.module.css";
import {MessageService} from "../../services/MessageService";
import Link from "antd/lib/typography/Link";
import Title from "antd/lib/typography/Title";
import {Divider} from "antd/lib";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import config from "../../config.json";

const {Option} = Select;

const NewCommScreen = (props: any) => {
    const location = useLocation();
    const {state} = location;
    const contact = state.contact;
    const navigate = useNavigate();
    const [format, setFormat] = useState("LI_MESSAGE");
    const [intents, setIntents] = useState<any>([]);
    const [voice, setVoice] = useState("Business Casual");
    const [length, setLength] = useState("Any");
    const [loading, setLoading] = useState(false);
    const [loadingTemplates, setLoadingTemplates] = useState(true);
    const [useCalendar, setUseCalendar] = useState(false);
    const [templates, setTemplates] = useState<any>([]);
    const [templateOptions, setTemplateOptions] = useState<any>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
    const [clearTemplateSelect, setClearTemplateSelect] = useState(false);
    const [clearIntentsList, setClearIntentsList] = useState(false);
    const [messageGenCapable, setMessageGenCapable] = useState(false);
    const [messageGenCapableError, setMessageGenCapableError] = useState(null)

    // These are for demo
    const {demo} = config
    const refTemplateSelect = useRef(null);
    const refManualInputs = useRef(null);
    const refFormatSelect = useRef(null);
    const refVoiceContainer = useRef(null);
    const refLengthContainer = useRef(null);
    const refPredefinedMessageSelect = useRef(null);
    const refIntentsContainer = useRef(null);
    const refRunButton = useRef(null);
    const [openTour, setOpenTour] = useState(demo)
    const tourSteps: TourProps['steps'] = [
        {
            title: 'Define Your Message',
            description: 'You\'ll set the inputs on this screen that will generate the perfect message for your recipient.',
            target: null
        },
        {
            title: 'Start Quick',
            description: 'Pick a template you have previously created from a message you liked to form the base of this new message. If you\'re looking for consistency and speed, this is the way to go.',
            target: () => refTemplateSelect.current,
            placement: 'bottom'
        },
        {
            title: 'Go Manual',
            description: 'If you are generating a message that an existing template doesn\'t pertain to, or want more control, then you can adjust the inputs directly.',
            target: () => refManualInputs.current,
            placement: 'top'
        },
        {
            title: 'Format',
            description: 'Select the message format that\'s appropriate what what you wish to send.',
            target: () => refFormatSelect.current,
            placement: 'right'
        },
        {
            title: 'Voice',
            description: 'Adjust the tone of the message for the relationship you have with your recipient.',
            target: () => refVoiceContainer.current,
            placement: 'right'
        },
        {
            title: 'Length',
            description: 'Choose how concise, or verbose, you prefer to be.',
            target: () => refLengthContainer.current,
            placement: 'right'
        },
        {
            title: 'Intents',
            description: 'Define the intentions of your message. You can start off with a good base by choosing from common predefined intention sets.',
            target: () => refPredefinedMessageSelect.current,
            placement: 'left'
        },
        {
            title: 'Intents',
            description: 'You can add or remove intents to help us understand exactly what you want to convey in your message.',
            target: () => refIntentsContainer.current,
            placement: 'left'
        },
        {
            title: 'You\'re ready to get started! ',
            description: 'Adjust the inputs to fit the message you want to generate and hit the \'Run\' button when ready.',
            target: () => refRunButton.current,
            placement: 'left'
        },
    ]


    const [messageConstraints, setMessageConstraints] = useState<any>({
        contact_id: contact.contact_id,
        format: format,
        intents: intents,
        voice: voice,
        length: length,
        useCalendar: useCalendar,
        messages: [],
    });

    useEffect(() => {
        console.log(`Intents: ${JSON.stringify(intents)}`);
    }, [intents]);

    useEffect(() => {
        refreshTemplates()
        checkCapable()
    }, [])

    useEffect(() => {
        refreshTemplateOptions()
    }, [templates])

    useEffect(() => {
        console.log(`clearTemplateSelect: ${clearTemplateSelect}`)
        if (clearTemplateSelect) {
            setSelectedTemplate(null)
        }
    }, [clearTemplateSelect])

    function checkCapable() {
        MessageService.getMessageGenCapableStatus(contact.id, contact.company_id, null, props.user).subscribe(
            (data: any) => {
                if (!data['capable']) {
                    setTimeout(checkCapable, 3000);
                } else {
                    setMessageGenCapable(true);
                }
            },
            (error: any) => {
                console.error(`Error getting message gen capable status: ${error}`);
                setMessageGenCapableError(error)
                toast.error(`Ineligible for message generation: contact not created successfully. Please contact support.`, {
                    position: "top-right",
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
        );
    }


    function refreshTemplates() {

        MessageService.getMessageTemplates(setLoadingTemplates, props.user).subscribe((data: any) => {
            console.log(`Got ${data.length} templates!`)
            setTemplates(data)
        }, (error: any) => {
            console.error(`Error getting templates: ${error}`)
            setLoadingTemplates(false)
        });
    }

    function refreshTemplateOptions() {
        const tmp: any = []
        if (templates.length > 0) {
            templates.forEach((t: any, index: number) => {
                tmp.push(<Option value={index}>{t['template_name']}</Option>)
            });
            setTemplateOptions(tmp);
        }
    }

    const handleClearIntentsList = (val: boolean) => {
        console.log('clearIntentsList updated from/to:', clearIntentsList, val);
        setClearIntentsList(!val);
        setClearIntentsList(val);
    };

    const handleTemplateSelect = (index: any) => {
        console.log(`Templates.length = ${templates.length}`)
        if (index >= 0 && index < templates.length) {
            setSelectedTemplate(templates[index])
            console.log(`Template Prompt: ${JSON.stringify(templates[index]?.prompt?.parameters, null, 2)}`)
            setClearTemplateSelect(false)
            handleClearIntentsList(true);
            console.log(`Chose template ${templates[index]['template_name']}`)
        } else {
            console.log(`Unable to get template for index ${index}`)
        }
    };

    const handleFormatChange = (value: string) => {
        setFormat(value);
    };

    const handleVoiceChange = (value: number) => {
        setVoice(voiceOptions[value]);
    };

    const handleLengthChange = (value: number) => {
        setLength(lengthOptions[value]);
    };

    const runTooltipTitle = () => {
        if (!messageGenCapable) {
            if (messageGenCapableError != null) {
                return 'This contact is not eligible for new messages.'
            } else {
                return 'Waiting for context to finish building...'
            }
        }
        return 'Generate Message'
    }

    const voiceTooltipFormatter: any = (value: number) => voiceOptions[value];
    const lengthTooltipFormatter: any = (value: number) => lengthTooltips[value];

    const onIntentsChange = (passedIntents: any) => {
        console.log(`Setting intents: ${JSON.stringify(passedIntents)}`);
        setIntents(passedIntents);
        if (passedIntents.length > 0) {
            setSelectedTemplate(null)
            setClearTemplateSelect(true)
            setClearIntentsList(false)
        } else {
            setClearTemplateSelect(false)
            setClearIntentsList(true)
        }
    };

    function goToMessage(message: any) {
        navigate(`/contacts/${contact.id}/message`, {state: {message: message}});
    }

    function getMessage() {
        setLoading(true);
        messageConstraints["format"] = format;
        messageConstraints["intents"] = JSON.stringify(intents);
        messageConstraints["template"] = selectedTemplate?.id
        messageConstraints["voice"] = voice;
        messageConstraints["length"] = length;
        messageConstraints["useCalendar"] = useCalendar;
        messageConstraints["contact_id"] = contact.id;
        setMessageConstraints(messageConstraints);
        console.log(`messageConstraints: ${JSON.stringify(messageConstraints)}`);
        ChatGPTService.getMessage(JSON.stringify(messageConstraints), setLoading, props.user).subscribe(
            (data: any) => {
                console.log(`Message: ${JSON.stringify(data)}`);
                window.heap.track("Message", data);
                messageConstraints["messages"].push(data);
                setMessageConstraints(messageConstraints);
                goToMessage(data);
            },
            (error: any) => {
                console.error(`Error fetching message: ${error}`);
            }
        );
    }

    const voiceOptions: any = {
        0: 'Casual',
        50: 'Business Casual',
        100: 'Professional'
    };

    const voiceOptionsReverse: any = {
        'Casual': 0,
        'Business Casual': 50,
        'Professional': 100
    };

    const lengthOptions: any = {
        0: 'Any',
        10: 'Short',
        50: 'Medium',
        100: 'Long'
    };

    const lengthOptionsReverse: any = {
        'Any': 0,
        'Short': 10,
        'Medium': 50,
        'Long': 100
    };

    const lengthTooltips: any = {
        0: 'Auto',
        10: '~30s read',
        50: '1-2m read',
        100: '2m+ read'
    }

    return (
        <Card>
            {!loadingTemplates &&
                <div style={{display: "flex", flexDirection: "column", width: "100%", height: "800"}}>
                    <div style={{flex: "1 0 auto", padding: "16px"}}>
                        {templateOptions?.length > 0 &&
                            <div>
                                <Row>
                                    <Col span={12}>
                                        <Title level={5} style={{display: 'inline'}}>Select A Message Template</Title>
                                    </Col>
                                    <Col span={12} style={{display: 'flex', justifyContent: 'end'}}>
                                        <Link onClick={() => {
                                        }}>
                                            Manage Templates
                                        </Link>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '4px'}}>
                                    <div style={{width: "100%"}} ref={refTemplateSelect}>
                                        <Select
                                            id='templates-select'
                                            style={{width: "100%"}}
                                            placeholder="Select Message Template"
                                            onSelect={handleTemplateSelect}
                                            value={clearTemplateSelect ? undefined : selectedTemplate?.template_name}
                                        >
                                            {templateOptions}
                                        </Select>
                                    </div>
                                </Row>
                                <Row style={{display: 'flex', justifyContent: 'center'}}>
                                    <Divider>OR</Divider>
                                </Row>
                            </div>
                        }
                        <Row>
                            <Col span={24}>
                                <Title level={5} style={{display: 'inline'}}>Define Message Format & Intentions</Title>
                            </Col>
                        </Row>
                        <Card style={{marginTop: '4px'}} ref={refManualInputs}>
                            <Row gutter={[16, 16]}>
                                <Col xs={12}>
                                    <Row>
                                        <div style={{width: "100%"}} ref={refFormatSelect}>
                                            <Select
                                                style={{width: "100%"}}
                                                value={format}
                                                onChange={handleFormatChange}
                                                placeholder="Format"
                                            >
                                                <Option value={"EMAIL"}>Email</Option>
                                                <Option value={"LI_CONNECTION_REQUEST"}>LinkedIn Connection
                                                    Request</Option>
                                                <Option value={"LI_MESSAGE"}>LinkedIn Message</Option>
                                            </Select>
                                        </div>
                                    </Row>
                                    <Row style={{marginTop: "16px"}}>
                                        <div ref={refVoiceContainer} style={{
                                            padding: '16px',
                                            width: '100%',
                                            borderRadius: '5px',
                                            borderColor: '#f0f0f0',
                                            borderStyle: 'solid',
                                            borderWidth: '1px'
                                        }}>
                                            <Typography.Text style={{
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                fontWeight: 400,
                                                fontSize: '0.75rem',
                                                lineHeight: '1.4375em',
                                                letterSpacing: '0.00938em'
                                            }}>Message Voice</Typography.Text>
                                            <div style={{
                                                width: '100%',
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginTop: '8px'
                                            }}>
                                                <div style={{width: "85%"}}>
                                                    <Slider
                                                        id={"voice-slider"}
                                                        defaultValue={50}
                                                        marks={voiceOptions}
                                                        step={25}
                                                        onChange={handleVoiceChange}
                                                        tooltip={{formatter: null}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                    {format != 'LI_CONNECTION_REQUEST' &&
                                        <Row style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginTop: "16px"
                                        }}>
                                            <div ref={refLengthContainer} style={{
                                                padding: '16px',
                                                width: '100%',
                                                borderRadius: '5px',
                                                borderColor: '#f0f0f0',
                                                borderStyle: 'solid',
                                                borderWidth: '1px'
                                            }}>
                                                <Typography.Text style={{
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 400,
                                                    fontSize: '0.75rem',
                                                    lineHeight: '1.4375em',
                                                    letterSpacing: '0.00938em'
                                                }}>Message Length</Typography.Text>
                                                <div style={{
                                                    width: '100%',
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    marginTop: '32px'
                                                }}>
                                                    <div style={{width: "85%"}}>
                                                        <Slider
                                                            id={"length-slider"}
                                                            defaultValue={0}
                                                            marks={lengthOptions}
                                                            step={25}
                                                            onChange={handleLengthChange}
                                                            tooltip={{formatter: lengthTooltipFormatter, open: true}}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    }
                                </Col>
                                <Col xs={12}>
                                    <Row>
                                        <div style={{width: "100%"}}>
                                            <IntentList refIntentsContainer={refIntentsContainer}
                                                        refPredefinedSelect={refPredefinedMessageSelect}
                                                        onIntentsChange={onIntentsChange} format={format}
                                                        clearIntentsList={clearIntentsList}/>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                        <Row style={{marginTop: "16px"}}>
                            <div style={{width: "100%"}}>
                                <Tooltip title={runTooltipTitle}>
                                    <Button
                                        size={"small"}
                                        type={"primary"}
                                        style={{float: "right"}}
                                        onClick={getMessage}
                                        loading={loading}
                                        icon={loading ? <LoadingOutlined/> : null}
                                        disabled={!messageGenCapable || (intents.length == 0 && !selectedTemplate)}
                                        ref={refRunButton}
                                    >
                                        Run
                                    </Button>
                                </Tooltip>
                            </div>
                        </Row>
                    </div>
                </div>
            }
            {loadingTemplates &&
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Spin/>
                </div>
            }
            <Tour open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps}/>
            <ToastContainer/>
        </Card>
    );
};
export default NewCommScreen;