const colors = {
    primary: '#4e67eb',
    secondary: '#291d89',
    success: '#4caf50',
    error: '#f44336',
    warning: '#ff9800',
    info: '#2196f3',
    light: '#f5f5f5',
    dark: '#212121',
    text: '#333',
    background: '#f0f4ff',
} as const;

export default colors;