import * as React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Typography
} from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import HeadCell from "../../interfaces/HeadCell";
import EnhancedTable from "../../common/EnhancedTable";
import ColumnProps from "../../interfaces/ColumnProps";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import {CompanyService} from "../../services/CompanyService";
import {TeamService} from "../../services/TeamService";
import EmailInput from "../../common/EmailInput";


const TeamTable = (props: any) => {
    const navigate = useNavigate();
    const [rows, setRows] = useState<any>([])
    const [members, setMembers] = useState<any>([])
    const [inviteMemberEmails, setInviteMemberEmails] = useState<string[]>([])
    const [loadingMembers, setLoadingMembers] = useState(true)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedMember, setSelectedMember] = useState<any>(null);
    const [menuItems, setMenuItems] = useState<any>([])
    const didMount = React.useRef(false)

    const headCells: readonly HeadCell[] = [
        {
            id: 'display_name',
            numeric: false,
            disablePadding: false,
            label: 'Full Name',
        },
        {
            id: 'date_invited',
            numeric: false,
            disablePadding: false,
            label: 'Date Created',
        },
        {
            id: 'role',
            numeric: false,
            disablePadding: false,
            label: 'Role',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
        },
    ];

    const columnPropsList: any = [
        {
            key: 'display_name',
            align: 'left',
            padding: 'normal'
        },
        {
            key: 'date_invited',
            align: 'left',
            padding: 'normal',
            type: 'date'
        },
        {
            key: 'role',
            align: 'left',
            padding: 'normal'
        },
        {
            key: 'status',
            align: 'left',
            padding: 'normal'
        }
    ]

    useEffect(() => {
        if (!didMount.current && props.user && props.user.company) {
            didMount.current = true;

            refreshMembers()
        }
        refreshTeamTable()
    }, [props, members])

    useEffect(() => {
        refreshMenuItems()
    }, [selectedMember])

    function refreshMembers() {
        setLoadingMembers(true)
        TeamService.getTeamMembers(props.team.id, setLoadingMembers, props.user).subscribe((data: any) => {
            console.log(`Received ${data.length} team members: \n${JSON.stringify(data, null, 2)}`)
            setMembers(data)
        }, (error: any) => {
            console.log(`Problem receiving team members: ${error}`)
        })
    }

    function refreshTeamTable() {
        console.log(`Refreshing team table`)
        const tmp: any = []
        members.forEach((user: any, index: number) => {
            tmp.push({
                key: user.id,
                obj: user
            })
        })
        setRows(tmp)
    }

    function refreshMenuItems() {
        if (selectedMember) {
            const tmp: any = []
            if (selectedMember.status == 'ACTIVE') {
                tmp.push(<MenuItem onClick={handleClose}>Remove</MenuItem>)
            } else if (selectedMember.status == 'PENDING') {
                tmp.push(<MenuItem onClick={handleClose}>Approve</MenuItem>)
                tmp.push(<MenuItem onClick={handleClose}>Decline</MenuItem>)
            }
            setMenuItems(tmp)
        }
    }

    function onAddUserClick() {
        console.log('Add User!')
        showAddMembersDialog()
    }

    function showAddMembersDialog() {
        setDialogOpen(true);
    }

    function handleDialogClose() {
        setDialogOpen(false);
    }

    function inviteTeamMembers() {
        setDialogOpen(false)
        console.log(`Inviting`, inviteMemberEmails)
    }

    const handleEmailsChange = (emails: string[]) => {
        console.log('Emails updated:', emails);
        setInviteMemberEmails(emails)
    };

    const handleRowClick = (event: React.MouseEvent<any>, member: any) => {
        console.log(`Row clicked: ${member.display_name}`);
        setSelectedMember(member);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };




    const actionButtonElement = (
        <Tooltip title="Add User">
            <IconButton onClick={onAddUserClick}>
                <AddIcon/>
            </IconButton>
        </Tooltip>
    )

    return (
        <Box>
            {loadingMembers &&
                <Box sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 2
                }}>
                    <CircularProgress/>
                </Box>
            }
            {!loadingMembers &&
                <EnhancedTable headCells={headCells} rows={rows} title={props.team.team_name + " Team"} defaultSort={'name'}
                                columnPropsList={columnPropsList} handleClick={handleRowClick}
                                actionButtonElement={actionButtonElement}/>
            }
            <Dialog onClose={handleDialogClose} open={dialogOpen}>
                <DialogTitle>Add Team Members</DialogTitle>
                <DialogContent>
                    <Grid xs={12}>
                        <Box sx={{paddingTop: 2, height: '100%'}}>
                            <EmailInput onChange={handleEmailsChange} />
                        </Box>
                    </Grid>
                    <Grid xs={12}>
                        <Button size={'small'} variant={'contained'}
                                sx={{float: 'right', marginTop: 2}} onClick={inviteTeamMembers}>
                            Invite
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {
                    menuItems
                }
            </Menu>
        </Box>
    );
};

export default TeamTable;
