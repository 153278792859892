import {
    Box,
    Button, Card,
    CardContent, Grid, Typography
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {ChangeEvent, useEffect, useState} from "react";
import TeamsTable from "./TeamsTable";
import * as React from "react";


const TeamsScreen = (props: any) => {

    useEffect(() => {
        console.log(`Company confirmed: ${props.user.company?.confirmed ?? false}`)
    })


    return (
        <Card>
            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '800'}}>
                <CardContent sx={{flex: '1 0 auto', padding: 8, marginBottom: 8}}>
                    {props.user.company && (props.user.company?.status ?? 'N/A') != 'ACTIVE' &&
                        <Grid container>
                            <Grid xs={12}>
                                <Typography variant={'h5'} sx={{fontWeight: 'bold'}}>You Are Not Part of an
                                    Organization</Typography>
                            </Grid>
                            <Grid xs={12} sx={{marginTop: 4}}>
                                <Typography>Request an invite from your administrator</Typography>
                            </Grid>
                            <Grid xs={12} sx={{marginTop: 4}}>
                                <Typography>or</Typography>
                            </Grid>
                            <Grid xs={12} sx={{marginTop: 4}}>
                                <Link to={'/new_org'} style={{color: 'inherit', textDecoration: 'none'}}>
                                    <Button variant={'contained'}>Start an Organization</Button>
                                </Link>
                            </Grid>
                        </Grid>
                    }
                    {props.user?.company && props.user.company?.status == 'ACTIVE' &&
                        <Grid container>
                            <Grid item xs={12} sx={{marginTop: 3}}>
                                <TeamsTable user={props.user}/>
                            </Grid>
                        </Grid>
                    }
                </CardContent>
            </Box>
        </Card>
    )
}

export default TeamsScreen;