import React, { useEffect } from "react";
import { Card, Col, Row, Avatar, Typography, Input, Form } from "antd";
import { deepOrange } from "@mui/material/colors";
import { getFormattedDate } from "../../utils/DateUtils";
import NoteList from "../../common/NoteList";
import MessageList from "../../common/MessageList";
import ContextCoverageList from "../../common/ContextCoverageList";
import TagsComponent from "../../common/TagsComponent";

const UserScreen = (props: any) => {
    useEffect(() => {
        console.log(`Props.user: ${JSON.stringify(props.user)}`);
    }, [props]);

    return (
        <Card>
            {props.user && (
                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "800" }}>
                    <div style={{ flex: "1 0 auto", padding: "16px" }}>
                        <Row gutter={[16, 16]}>
                            <Col xs={12}>
                                <Row align="middle">
                                    <Col span={3}>
                                        {props.user.profile_pic && (
                                            <Avatar size={52} src={props.user.profile_pic}></Avatar>
                                        )}
                                        {!props.user.profile_pic && (
                                            <Avatar size={52}
                                                style={{
                                                    backgroundColor: deepOrange[500],
                                                }}
                                            >
                                                {props.user.first_name.charAt(0)}
                                            </Avatar>
                                        )}
                                    </Col>
                                    <Col span={21}>
                                        <Row>
                                            <Col span={18}>
                                                <Typography.Title level={4} style={{ fontWeight: "bold", marginRight: "8px", marginBottom: 0, marginTop: 0}}>
                                                    {props.user.first_name} {props.user.last_name}
                                                </Typography.Title>
                                            </Col>
                                            <Col span={6}>
                                                <Typography.Text style={{ float: "right" }}>
                                                    Added {getFormattedDate(props.user.date_added)}
                                                </Typography.Text>
                                            </Col>
                                            <Col span={24}>
                                                <Typography.Text style={{ marginRight: "8px" }}>{props.user.company?.company_name ?? 'No Company'}</Typography.Text>
                                            </Col>
                                            <Col span={24}>
                                                <TagsComponent
                                                    entityType={"USER"}
                                                    entityId={props.user.id}
                                                    tagType={"ROLE"}
                                                    user={props.user}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Typography.Paragraph style={{ marginTop: "16px" }}>
                                            {props.user.summary}
                                        </Typography.Paragraph>
                                        <div style={{marginTop: 16}}>
                                            <Input
                                                addonBefore="https://www.linkedin.com/in/"
                                                value={props.user.linkedin_id}
                                                readOnly
                                            />
                                        </div>
                                        <div style={{ marginTop: "16px" }}>
                                            <ContextCoverageList />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12}>
                                <NoteList user={props.user} />
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
        </Card>
    );
};

export default UserScreen;