import GeneralService from "./GeneralService";
import { Component } from "react";

export class ContactService extends Component {
    public static createContacts(body: string, loadingHandler: any, user: any) {
        return GeneralService.post('/contact/v3/new_bulk', body, loadingHandler, JSON, user);
    }

    public static getContacts(userId: number, loadingHandler: any, user: any) {
        return GeneralService.get(`/contact/${userId}/all`, loadingHandler, JSON, user)
    }

    public static getContact(contactId: number, loadingHandler: any, user: any) {
        return GeneralService.get(`/contact/${contactId}`, loadingHandler, JSON, user)
    }

}