import * as React from 'react';
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import {
    Box, CircularProgress,
    FormControl, IconButton,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {FavoriteBorderOutlined} from "@mui/icons-material";
import {Col, Row} from "antd";
import {LineOutlined, EllipsisOutlined, FieldTimeOutlined, CheckOutlined, SaveOutlined} from "@ant-design/icons"
import theme from "../../assets/theme";
import {Tooltip} from "antd/lib";
import '../../css/pulseAnimation.css';

const MessageComponent = forwardRef((props: any, ref: any) => {
    const [messageComponents, setMessageComponents] = useState<any>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [segments, setSegments] = useState<any>([])
    const [segmentRefs, setSegmentRefs] = useState<any>([])
    const [segmentFeedbackValue, setSegmentFeedbackValue] = useState<string>('');
    const [focusedSegmentIndex, setFocusedSegmentIndex] = useState(-1)
    const [segmentFeedbacks, setSegmentFeedbacks] = useState<any>([])
    const [wordCountString, setWordCountString] = useState('--')
    const [charCountString, setCharCountString] = useState('--')
    const [readTimeString, setReadTimeString] = useState('00:00')
    const clickCount = useRef(0);
    const tripleClickTimeout = useRef<NodeJS.Timeout | null>(null);
    const [draftModified, setDraftModified] = useState(false);


    const open = Boolean(anchorEl);
    const styles: any = {
        segment: {
            whiteSpace: 'pre-wrap',
            cursor: 'pointer',
            color: 'black',
            "&:hover": {
                color: '#1976d2'
            },
            "&:focus": {
                outline: "none", // Remove the default browser outline
                backgroundColor: "#f1f1f1", // Add a background color to differentiate it from non-focused segments
                padding: "4px", // Add the desired padding
                borderRadius: "4px", // Add a border radius to smooth the corners
            },
        }
    }

    useImperativeHandle(ref, () => ({
        clearSegmentFeedbacks() {
            setSegmentFeedbacks([])
        }
    }));

    useEffect(() => {
        console.log('useEffect segmentFeedbacks')
        refreshMessageComponents()
    }, [segmentFeedbacks])

    useEffect(() => {
        console.log('useEffect props.message')
        setDraftModified(false)
        buildSegments()
    }, [props.message])

    useEffect(() => {
        console.log('useEffect messageComponents')
        setTopMetrics()
    }, [messageComponents])

    useEffect(() => {
        console.log('useEffect segments')
        props.onSegmentsChange(segments)
        refreshMessageComponents()
    }, [segments])

    const handleClose = () => {
        setAnchorEl(null);
        if (focusedSegmentIndex >= 0) {
            segmentRefs[focusedSegmentIndex].current.style = styles.segment
        }
        setSegmentFeedbackValue('')
        setFocusedSegmentIndex(-1);
    };

    const addSegmentFeedback = () => {
        console.log(`Adding segment feedback: ${segmentFeedbackValue}`)
        const newSegmentFeedbacks = [...segmentFeedbacks, {
            'index': focusedSegmentIndex,
            'segment': segments[focusedSegmentIndex],
            'feedback': segmentFeedbackValue
        }]
        setSegmentFeedbacks(newSegmentFeedbacks)
        props.onSegmentFeedbacksChange(newSegmentFeedbacks)
        handleClose()
    }

    function removeSegmentFeedback(segmentIndex: number) {
        const feedbackIndex = segmentFeedbacks.findIndex(
            (feedback: any) => feedback['index'] === segmentIndex
        );

        if (feedbackIndex !== -1) {
            const tmp = [
                ...segmentFeedbacks.slice(0, feedbackIndex),
                ...segmentFeedbacks.slice(feedbackIndex + 1),
            ];
            setSegmentFeedbacks(tmp);
            props.onSegmentFeedbacksChange(tmp);
        }
    }


    const handleSegmentTripleClick = (
        event: React.MouseEvent<HTMLElement>,
        ref: any,
        segment: string,
        index: number) => {
        event.currentTarget.focus()
        setFocusedSegmentIndex(index);
        setAnchorEl(event.currentTarget);
        event.currentTarget.style.color = '#1976d2'
        const selection = window.getSelection();
        if (selection && ref.current) {
            selection.setBaseAndExtent(ref.current, 0, ref.current, ref.current.childNodes.length);
        }
    };

    const handleClicks = (
        event: React.MouseEvent<HTMLElement>,
        ref: any,
        segment: string,
        index: number) => {
        clickCount.current++;
        if (clickCount.current === 3) {
            if (tripleClickTimeout.current) {
                clearTimeout(tripleClickTimeout.current);
            }
            clickCount.current = 0;
            handleSegmentTripleClick(event, ref, segment, index);
        } else if (clickCount.current === 2) {
            if (tripleClickTimeout.current) {
                clearTimeout(tripleClickTimeout.current);
            }
            tripleClickTimeout.current = setTimeout(() => {
                clickCount.current = 0;
                // handleSegmentDoubleClick(event, segment, index);
            }, 500);
        } else {
            tripleClickTimeout.current = setTimeout(() => {
                clickCount.current = 0;
                // handleSegmentSingleClick(event, segment, index);
            }, 500);
        }
    };

    const handleSegmentBlur = (event: React.FocusEvent<HTMLElement>, index: number) => {
        const updatedSegment = event.target.textContent;
        if (segments[index] !== updatedSegment) {
            console.log(`Updated segments! ${segments[index]} != ${updatedSegment}`)
            const updatedSegments = structuredClone(segments);
            updatedSegments[index] = updatedSegment;
            setSegments(updatedSegments);
            setDraftModified(true); // Update the draftModified state
        }
    };

    function handleSegmentKeyPress(event: any) {
        if (event.keyCode === 13) {
            event.preventDefault()
            addSegmentFeedback()
            event.target.blur()
        }
    }

    const handleSegmentFeedbackChange = (event: any) => {
        setSegmentFeedbackValue(event.target.value)
    }

    const removeSegment = (event: any) => {
        console.log(`Removing segment ${focusedSegmentIndex}: ${segments[focusedSegmentIndex]}`)
        const newSegments = structuredClone(segments)
        newSegments.splice(focusedSegmentIndex, 1)
        setSegments(newSegments)
        handleClose()
    }

    function refreshMessageComponents() {
        if (segments.length > 0 && segments[0].trim().length == 0) {
            const newSegments = structuredClone(segments)
            newSegments.splice(0, 1)
            setSegments(newSegments)
            handleClose()
            return;
        }
        let tmp: any = []
        const segRefs: any = []
        segments.forEach((segment: any, index: number) => {
            const ref = React.createRef<any>();
            segRefs.push(ref)
            console.log(`seg[${index}]: ${segment.substring(0, 10)}`)
            tmp.push(<Typography key={index} ref={ref}
                                 onMouseDown={(event) => handleClicks(event, ref, segment, index)}
                                 contentEditable
                                 onBlur={(event) => handleSegmentBlur(event, index)}
                                 onKeyDown={(event: any) => {
                                     event.stopPropagation();
                                     if (event.keyCode === 13 && !event.shiftKey) {
                                         event.preventDefault();
                                         event.target.blur();
                                     }
                                 }}
                                 sx={{...styles.segment}}>{segment}</Typography>)
            if (segmentFeedbacks.length > 0) {
                const filteredFeedback = segmentFeedbacks.filter((fb: any) => fb['index'] === index)
                if (filteredFeedback && filteredFeedback.length > 0) {
                    const feedback = filteredFeedback[0]
                    console.log(`Feedback: ${JSON.stringify(feedback)}`)
                    const comp = <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <IconButton size={'small'} component={'label'}
                                    onClick={() => removeSegmentFeedback(feedback['index'])}>
                            <RemoveIcon color={'error'}/>
                        </IconButton>
                        <Typography style={{color: 'red'}}>{feedback['feedback']}</Typography>
                    </Box>
                    tmp.push(comp)
                }
            }
        })
        setSegmentRefs(segRefs)
        setMessageComponents(tmp)
    }

    function buildSegments() {
        const copy = structuredClone(props.message)
        const replaced = copy.replace(/([\r\n])|\s{2,}/g, "$1|")
        const segments_tmp = replaced.split("|")
        setSegments(segments_tmp)
    }

    function setTopMetrics() {
        const segmentClone = structuredClone(segments)
        const msg = segmentClone.join(" ")
        const words = msg.split(/\s+/g)
        const characters = msg.length
        const readString = estimateReadingTime(msg)

        setWordCountString(`${words.length}`)
        setCharCountString(`${characters}`)
        setReadTimeString(`${readString}`)
    }

    function estimateReadingTime(text: string): string {
        const wordsPerMinute = 200;
        const wordCount = text.split(/\s+/g).length;
        const minutes = Math.floor(wordCount / wordsPerMinute);
        const seconds = Math.round((wordCount / wordsPerMinute - minutes) * 60);

        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    const copyMessage = () => {
        const messageStr: string = segments.join("")
        navigator.clipboard.writeText(messageStr)
        props.copyMessage()
    }

    const completeDraft = () => {
        console.log('Clicked Complete Draft')
        props.completeDraft()
    };

    const createTemplate = () => {
        console.log('Clicked Create Template')
        props.createTemplate()
    }

    return (
        <div style={{width: '100%'}}>
            <Row style={{marginLeft: '24px'}}>
                <Col span={18} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} ref={props?.refMetrics}>
                    <Tooltip title={"Word Count"}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <LineOutlined style={{marginRight: '4px', color: theme.primary}}/>
                            <Typography sx={{marginRight: '16px'}}>{wordCountString}</Typography>
                        </div>
                    </Tooltip>
                    <Tooltip title={"Character Count"}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <EllipsisOutlined style={{marginRight: '4px', color: theme.primary}}/>
                            <Typography sx={{marginRight: '16px'}}>{charCountString}</Typography>
                        </div>
                    </Tooltip>
                    <Tooltip title={"Read Time"}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <FieldTimeOutlined style={{marginRight: '4px', color: theme.primary}}/>
                            <Typography sx={{marginRight: '16px'}}>{readTimeString}</Typography>
                        </div>
                    </Tooltip>
                </Col>
                <Col span={6}>
                    <Box sx={{float: 'right'}}>
                        <IconButton><ThumbUpOffAltIcon/></IconButton>
                        <IconButton><ThumbDownOffAltIcon/></IconButton>
                        <Tooltip title={"Create Template"}>
                            <IconButton ref={props?.refTemplate} onClick={createTemplate}><FavoriteBorderOutlined/></IconButton>
                        </Tooltip>
                        <Tooltip title={"Copy Message"}>
                            <IconButton ref={props?.refCopy} onClick={copyMessage}><ContentCopyIcon/></IconButton>
                        </Tooltip>
                    </Box>
                </Col>
            </Row>
            <Box id="message-box" sx={{
                position: 'relative',
                padding: 2,
                width: '100%',
                borderRadius: '4px',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                borderStyle: 'solid',
                borderWidth: '1px',
                marginTop: '4px'
            }}>
                {!props.loading &&
                    <Box ref={props?.refMessage} sx={{
                        width: 'inherit', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start',
                        flexFlow: 'column wrap'
                    }}>
                        {messageComponents}

                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                        >
                            <MenuItem onClick={(event: any) => removeSegment(event)}>Remove Segment</MenuItem>
                            <MenuItem sx={{paddingTop: 2, paddingBottom: 1}}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="component-outlined">Segment Revisions</InputLabel>
                                    <OutlinedInput
                                        id="component-outlined"
                                        label="Segment Revisions"
                                        value={segmentFeedbackValue}
                                        onChange={(event) => handleSegmentFeedbackChange(event)}
                                        onKeyDown={(event: any) => {
                                            event.stopPropagation();
                                            handleSegmentKeyPress(event);
                                        }}
                                    />
                                </FormControl>
                                {segmentFeedbackValue.length > 1 &&
                                    <IconButton size={'small'} component={'label'}
                                                sx={{float: 'right', marginLeft: 2}} onClick={addSegmentFeedback}>
                                        <AddIcon color={'primary'}/>
                                    </IconButton>
                                }
                            </MenuItem>
                        </Menu>
                    </Box>
                }
                {props.loading &&
                    <Box sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 2
                    }}>
                        <CircularProgress/>
                    </Box>
                }
                {draftModified && !props.loading && (
                    <Tooltip title={"Complete Draft"}>
                        <CheckOutlined onClick={completeDraft}
                                       style={{
                                           position: 'absolute',
                                           bottom: "8px",
                                           right: "8px",
                                           color: theme.primary,
                                           fontSize: '16px'
                                       }}
                                       className={'pulse'}
                        />
                    </Tooltip>
                )}
            </Box>
        </div>
    );
});

export default MessageComponent;
