import React, {useEffect, useState} from 'react';
import NewCommScreen from "./routes/new_comm/NewCommScreen";
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import Layout from "./Layout";
import ContactRoute from "./routes/contact/ContactRoute";
import ContactScreen from "./routes/contact/ContactScreen";
import ContactsRoute from "./routes/contacts/ContactsRoute";
import ContactsScreen from "./routes/contacts/ContactsScreen";
import HomeScreen from "./routes/home/HomeScreen";
import NewContactScreen from "./routes/new_contact/NewContactScreen";
import MessageScreen from "./routes/message/MessageScreen";
import UserRoute from "./routes/user/UserRoute";
import UserScreen from "./routes/user/UserScreen";
import AuthScreen from "./routes/auth/AuthScreen";
import TeamsRoute from "./routes/teams/TeamsRoute";
import TeamsScreen from "./routes/teams/TeamsScreen";
import NewOrgRoute from "./routes/new_org/NewOrgRoute";
import NewOrgScreen from "./routes/new_org/NewOrgScreen";
import TeamScreen from "./routes/team/TeamScreen";
import {createTheme} from "@material-ui/core";
import {ThemeProvider} from "@mui/material";
import theme from "./assets/theme";
import {ConfigProvider} from "antd";

function App() {

    const [user, setUser] = useState<any>(null);


    useEffect(() => {
        console.log(`User has changed: ${JSON.stringify(user)}`)
    }, [user]);

    const handleUserLogin = (userData: any) => {
        console.log(`Handled User Login: ${JSON.stringify(userData)}`)
        window.heap.identify(userData['email']);
        window.heap.addUserProperties(userData)
        window.markerConfig = {
            project: '642e53bfd42bbd9f7a06ca25',
            source: 'snippet',

            // Identify your reporter
            reporter: {
                email: userData['email'],
                fullName: userData['display_name'],
            },
        }
        setUser(userData);
    };

    const handleUserLogout = () => {
        console.log('Logging out from App')
        setUser(null);
    };

    const muiTheme = createTheme({
        palette: {
            type: 'light',
            primary: {
                main: theme.primary,
            },
            secondary: {
                main: theme.secondary,
            },
            // Add other colors as needed
            error: {
                main: theme.error,
            },
            warning: {
                main: theme.warning,
            },
            info: {
                main: theme.info,
            },
            success: {
                main: theme.success,
            },
            background: {
                default: theme.background, // Light background for a clean appearance
            },
            text: {
                primary: theme.text
            },
        },
    });

    const antdTheme = {
        token: {
            colorPrimary: theme.primary,
            colorError: theme.error,
            colorWarning: theme.warning,
            colorInfo: theme.info,
            colorSuccess: theme.success,
            colorTextBase: theme.text,
        }
    }


    return (
        <ThemeProvider theme={muiTheme}>
            <ConfigProvider theme={antdTheme}>
                <BrowserRouter>
                    <Routes>
                        {user &&
                            <Route path={'/'} element={<Layout user={user} onUserLogout={handleUserLogout}/>}>
                                <Route index element={<HomeScreen user={user}/>}/>
                                <Route path={'/user'} element={<UserRoute/>}>
                                    <Route index element={<UserScreen user={user}/>}/>
                                </Route>
                                <Route path={'/teams'} element={<TeamsRoute/>}>
                                    <Route index element={<TeamsScreen user={user}/>}/>
                                    <Route path={':id'} element={<TeamScreen user={user}/>}/>
                                </Route>
                                <Route path={'/new_org'} element={<NewOrgRoute/>}>
                                    <Route index element={<NewOrgScreen user={user}/>}/>
                                </Route>
                                <Route path={'/contacts'} element={<ContactsRoute/>}>
                                    <Route index element={<ContactsScreen user={user}/>}/>
                                    <Route path={'new'} element={<NewContactScreen user={user}/>}/>
                                    <Route path={':id'} element={<ContactRoute/>}>
                                        <Route index element={<ContactScreen user={user}/>}/>
                                        <Route path={'new'} element={<NewCommScreen user={user}/>}/>
                                        <Route path={'message'} element={<MessageScreen user={user}/>}/>
                                    </Route>
                                </Route>
                            </Route>
                        }
                        {!user &&
                            <Route path={'*'} element={<AuthScreen onUserLogin={handleUserLogin}/>}/>
                        }
                    </Routes>
                </BrowserRouter>
            </ConfigProvider>
        </ThemeProvider>
    );
}

export default App;
