import React, { useEffect, useState } from "react";
import { Card, Button, Space, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ContactList from "../../common/ContactList";
import { ContactService } from "../../services/ContactService";
import { Link } from "react-router-dom";

const { Title } = Typography;

const ContactsScreen = (props: any) => {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log(`ContactsScreen user: ${props.user ? JSON.stringify(props.user) : 'None'}`)
        if (props.user) {
            getContacts()
        }
    }, [props]);

    function getContacts() {
        ContactService.getContacts(props.user.id, setLoading, props.user).subscribe((data: any) => {
            console.log(`Contacts: ${JSON.stringify(data)}`)
            setContacts(data)
        }, (error: any) => {
            console.error(`Error getting contacts: ${error}`)
        });
    }

    return (
        <Card style={{ height: "800px", display: "flex", flexDirection: "column" }}>
            <Space direction="vertical" style={{ flex: "1 0 auto", width: '100%' }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Link to={"/contacts/new"} style={{ color: "inherit", textDecoration: "none" }}>
                        <Button type="primary" icon={<PlusOutlined />}>
                            New
                        </Button>
                    </Link>
                </div>
                <div style={{ paddingTop: "16px", paddingLeft: "16px", width: '100%' }}>
                    <ContactList contacts={contacts} user={props.user} loading={loading} />
                </div>
            </Space>
        </Card>
    );
};

export default ContactsScreen;
