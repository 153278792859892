import {
    Box,
    Button, Card,
    CardContent, FormControl, Grid, InputLabel, OutlinedInput, Typography
} from "@mui/material";
import user from "../../dummy/user";
import AddIcon from "@mui/icons-material/Add";
import {Link, useNavigate} from "react-router-dom";
import React, {ChangeEvent, useState} from "react";
import {CompanyService} from "../../services/CompanyService";
import LoadingButton from "@mui/lab/LoadingButton";


const NewOrgScreen = (props: any) => {

    const [companyName, setCompanyName] = useState<any>(props.user.company?.company_name ?? 'No Company')
    const [companyWebsite, setCompanyWebsite] = useState<any>(props.user.company?.company_website ?? 'No Company Website')
    const [loadingConfirm, setLoadingConfirm] = useState(false)
    const navigate = useNavigate();

    const handleCompanyNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCompanyName(event.target.value);
    }

    const handleCompanyWebsiteChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCompanyWebsite(event.target.value);
    }

    function confirmCompany() {
        setLoadingConfirm(true)
        CompanyService.confirmCompany(props.user.company.id, setLoadingConfirm, props.user).subscribe((data: any) => {
            console.log('Confirmed company!')
            props.user.company.confirmed = true
            navigate('/teams')
        }, (error: any) => {
            console.error(`Error confirming company: ${error}`)
        });
    }

    return (
        <Card>
            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '800'}}>
                <CardContent sx={{flex: '1 0 auto', padding: 8, marginBottom: 8}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant={'h5'} sx={{fontWeight: 'bold'}}>New Organization</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: 5}}>
                            <FormControl>
                                <InputLabel htmlFor="component-outlined">Company Name</InputLabel>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={companyName}
                                    label="Company Name"
                                    placeholder={'Your Company Name Here'}
                                    onChange={handleCompanyNameChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: 5}}>
                            <FormControl>
                                <InputLabel htmlFor="component-outlined">Company Website</InputLabel>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={companyWebsite}
                                    label="Company Website"
                                    placeholder={'https://yourcompany.com'}
                                    onChange={handleCompanyWebsiteChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: 5}}>
                            {companyName.length > 0 && companyWebsite.length > 0 &&
                                <LoadingButton size={'medium'} variant={'contained'}
                                               onClick={confirmCompany} loading={loadingConfirm}>
                                    Confirm
                                </LoadingButton>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Box>
        </Card>
    )
}

export default NewOrgScreen;