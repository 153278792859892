import * as React from "react";
import {
    List,
    Input,
    Button,
    Typography,
    Card,
    Spin,
    Space,
    Divider, Row, Col,
} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {ChangeEvent, useEffect, useState} from "react";
import {ContextService} from "../services/ContextService";
import {getFormattedDate} from "../utils/DateUtils";
import TextArea from "antd/lib/input/TextArea";

const NoteList = (props: any) => {
    const [newNote, setNewNote] = useState("");
    const [loadingNotes, setLoadingNotes] = useState(true);
    const [loadingAddNote, setLoadingAddNote] = useState(false)
    const [notes, setNotes] = useState<any>([]);
    const entityType = props.contact ? "CONTACT" : "USER";
    const entityId = props.contact ? props.contact.id : props.user.id;
    const didMount = React.useRef(false);

    useEffect(() => {
        if (!didMount.current && props.user) {
            didMount.current = true;
            if (entityType && entityId > 0) {
                getNotes();
            }
        }
        refreshNotesList();
    }, [notes, props]);

    const handleNewNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setNewNote(event.target.value);
    };

    const addNewNote = () => {
        if (newNote.length <= 5) return;
        setLoadingAddNote(true)
        console.log(`Adding new note: ${newNote}`);
        const note = {
            context_entity_type: entityType,
            context_entity_id: entityId,
            context_type: "NOTE",
            added_by_user: props.user.id,
            context_body: newNote,
        };
        ContextService.addContext(JSON.stringify(note), setLoadingAddNote, props.user).subscribe(
            (data: any) => {
                setNewNote("");
                console.log(`Added Note: ${JSON.stringify(data)}`);
                getNotes();
            },
            (error: any) => {
                console.error(`Error adding note: ${error}`);
            }
        );
    };

    function deleteNote(note: any) {
        setLoadingNotes(true)
        ContextService.deleteContext(note.id, setLoadingNotes, props.user).subscribe(
            (data: any) => {
                console.log(`Data: ${JSON.stringify(data)}`);
                getNotes();
            },
            (error: any) => {
                console.error(`Error deleting note: ${error}`);
                setLoadingNotes(false)
            }
        );
    }

    function getNotes() {
        setLoadingNotes(true)
        ContextService.getContexts(entityType, entityId, "NOTE", setLoadingNotes, props.user).subscribe(
            (data: any) => {
                console.log(`Notes: ${JSON.stringify(data)}`);
                setNotes(data);
            },
            (error: any) => {
                console.error(`Error getting notes: ${error}`);
            }
        );
    }

    function refreshNotesList() {
        console.log(`notes length: ${notes.length}`);
    }


    function handleNoteKeyPress(event: React.KeyboardEvent) {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevents adding a new line in the TextArea
            addNewNote();
        }
    }

    return (
        <Card style={{height: "inherit"}}>
            <Row style={{width: "100%"}}>
                <Col flex="auto">
                    <TextArea
                        placeholder="Add a new note..."
                        value={newNote}
                        onChange={handleNewNoteChange}
                        onKeyDown={handleNoteKeyPress}
                        autoSize={{minRows: 1, maxRows: 2}}
                        style={{marginRight: "5px"}}
                    />
                </Col>
                <Col>
                    <Button type="primary"
                            onClick={addNewNote}
                            style={{marginLeft: '4px'}}
                            loading={loadingAddNote}>
                        Add
                    </Button>
                </Col>
            </Row>
            {loadingNotes ? (
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spin />
                </div>
            ) : (
                <List
                    dataSource={notes}
                    renderItem={(note: any) => (
                        <>
                            <List.Item
                                key={note.id}
                                actions={[
                                    <Button
                                        key="delete"
                                        icon={<DeleteOutlined/>}
                                        onClick={() => deleteNote(note)}
                                        loading={loadingNotes}
                                    />,
                                ]}
                            >
                                <List.Item.Meta
                                    title={note.context_summary}
                                    description={`Added by ${note.added_by_user.first_name} ${note.added_by_user.last_name} on ${getFormattedDate(
                                        note.date_added
                                    )}`}
                                />
                            </List.Item>
                        </>
                    )}
                    locale={{emptyText: <Typography>No Notes</Typography>}}
                />
            )}
        </Card>
    );
};

export default NoteList;


