import GeneralService from "./GeneralService";
import { Component } from "react";

export class TagService extends Component {

    public static getTags(entity_type: string, entity_id: number, tag_type: string, loadingHandler: any, user: any) {
        return GeneralService.get(`/tag/${entity_type}/${entity_id}/${tag_type}`, loadingHandler, JSON, user)
    }

    public static addTag(body: string, loadingHandler: any, user: any) {
        return GeneralService.post('/tag/new', body, loadingHandler, JSON, user);
    }

    public static deleteTag(tag_id: number, loadingHandler: any, user: any) {
        return GeneralService.delete(`/tag/${tag_id}`, loadingHandler, JSON, user);
    }

}