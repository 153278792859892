import * as React from 'react';
import {
    Box,
    Card,
    List,
    ListItem,
    Typography
} from "@mui/material";
import {ChangeEvent, useEffect, useState} from "react";
import LinearProgressWithLabel from "./LinearProgressWithLabel";

const ContextCoverageList = (props: any) => {
    const didMount = React.useRef(false)
    const [listItems, setListItems] = useState<any>([])


    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
        }
        refreshItems()
    }, [])

    function refreshItems() {
        const tmp: any = []
        tmp.push(<ListItem key={0} sx={{width: '100%'}}>
            <Box sx={{ width: '100%' }}>
                <Typography>User</Typography>
                <LinearProgressWithLabel value={60} />
            </Box>
        </ListItem>
        )
        tmp.push(<ListItem key={1} sx={{width: '100%'}}>
                <Box sx={{ width: '100%' }}>
                    <Typography>Company</Typography>
                    <LinearProgressWithLabel value={85} />
                </Box>
            </ListItem>
        )
        tmp.push(<ListItem key={2} sx={{width: '100%'}}>
                <Box sx={{ width: '100%' }}>
                    <Typography>Shared Communications</Typography>
                    <LinearProgressWithLabel value={0} />
                </Box>
            </ListItem>
        )
        setListItems(tmp)
    }

    return (
        <Box>
            <Card variant={'outlined'} sx={{height: 'inherit'}}>
                <Typography sx={{marginTop: '1em', marginLeft: '1em', color: 'rgba(0, 0, 0, 0.6)',
                fontWeight: 400, fontSize: '0.75rem', lineHeight: '1.4375em', letterSpacing: '0.00938em'}}
                >Context Coverage</Typography>
                <List sx={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>
                    {listItems}
                </List>
            </Card>
        </Box>
)
    ;
};

export default ContextCoverageList;
