import * as React from 'react';
import {Box, Card, CircularProgress, Divider, Grid, List, ListItem, Typography} from "@mui/material";
import {toTitleCase} from "../utils/StringUtils";
import {getFormattedDate} from "../utils/DateUtils";
import {Link} from "react-router-dom";
import {useState} from "react";
import {FormatQuote} from "@mui/icons-material";

const MessageVersionList= (props: any) => {
    let listItems: any = []
    const [chosenMessageIndex, setChosenMessageIndex] = useState<any>(props.chosenIndex ? props.chosenIndex : 0)

    function chooseMessage(message: any, index: number) {
        setChosenMessageIndex(index);
        props.onClick(message)
    }

    if (props.messages) {
        props.messages.forEach((message: any, index: number) => {
            listItems.push(
                <ListItem key={index} onClick={() => chooseMessage(message, index)} sx={{backgroundColor: index == chosenMessageIndex ? 'rgb(231, 235, 240)' : 'white'}}>
                    <Grid container>
                        <Grid xs={9} item>
                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                            </Box>
                        </Grid>
                        <Grid xs={3} item>
                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                <Typography>{getFormattedDate(message.date_generated)}</Typography>
                                <Typography sx={{fontWeight: 'bold', marginLeft: 1}}>v{message.version}</Typography>
                            </Box>
                        </Grid>
                        <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                            {message.message_type == 'MESSAGE_REVISED' &&
                                <FormatQuote fontSize={'small'} sx={{marginRight: 1}}/>
                            }
                            <Typography>
                                {message.summary}
                            </Typography>
                        </Box>
                    </Grid>
                </ListItem>
            );
            listItems.push(
                <Divider key={`d${index}`}/>
            );
        })
    }
    return (
        <Card variant={'outlined'} sx={{height: 'inherit'}} ref={props?.ref}>
            {!props.loading &&
                <List sx={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>
                    {listItems}
                </List>
            }
            {props.loading &&
                <Box sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 2
                }}>
                    <CircularProgress/>
                </Box>
            }
        </Card>
    );
};

export default MessageVersionList;
