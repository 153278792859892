import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GoogleOAuthProvider} from "@react-oauth/google";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

// Add Referrer Policy
const meta = document.createElement('meta');
meta.name = 'referrer';
meta.content = 'no-referrer-when-downgrade';
document.getElementsByTagName('head')[0].appendChild(meta);


root.render(
    <GoogleOAuthProvider clientId={'620851676735-o21ftvu2scfpqsffi3t9b1nh0iubjpqf.apps.googleusercontent.com'}>
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
