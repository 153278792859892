import React from "react";
import { Avatar, Card, List, Typography, Divider, Space } from "antd";
import { deepOrange } from "@mui/material/colors";
import { getFormattedDate } from "../utils/DateUtils";
import { Link } from "react-router-dom";

interface ContactListProps {
    contacts: any[];
    user: any;
    loading: boolean;
}

const ContactList: React.FC<ContactListProps> = (props) => {
    const { contacts, loading } = props;

    const listItems = contacts.map((contact, index) => (
        <React.Fragment key={index}>
            <Link
                to={`/contacts/${contact.id}`}
                state={{ contact }}
                style={{ textDecoration: "inherit", color: "inherit" }}
            >
                <List.Item>
                    <Space>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "16px",
                            }}
                        >
                            {contact.profile_pic ? (
                                <Avatar src={contact.profile_pic}></Avatar>
                            ) : (
                                <Avatar style={{ backgroundColor: deepOrange[500] }}>
                                    {contact.first_name.charAt(0)}
                                </Avatar>
                            )}
                        </div>
                        <div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography.Text strong>
                                    {contact.first_name} {contact.last_name}
                                </Typography.Text>
                                <Typography.Text style={{ marginLeft: "8px" }}>
                                    {contact.company?.company_name ?? 'No Company'}
                                </Typography.Text>
                            </div>
                            <div>
                                <Typography.Text type="secondary">
                                    Added {getFormattedDate(contact.date_added)}
                                </Typography.Text>
                            </div>
                            <Typography.Text>{contact.summary}</Typography.Text>
                        </div>
                    </Space>
                </List.Item>
            </Link>
            {index < contacts.length - 1 && <Divider />}
        </React.Fragment>
    ));

    return (
        <Card style={{maxHeight: '600px', overflow: 'auto'}}>
            {!loading && listItems.length > 0 && (
                <List style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}>
                    {listItems}
                </List>
            )}
            {!loading && listItems.length === 0 && (
                <List className={'contactList'} style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Typography.Text>No Contacts</Typography.Text>
                    </div>
                </List>
            )}
            {loading && (
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography.Text>Loading...</Typography.Text>
                </div>
            )}
        </Card>
    );
};

export default ContactList;
