import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import {Box} from "@mui/material";
import {Col, List, Row, Space} from "antd/lib";
import {Input, Modal, Radio, Button} from "antd";
import {MessageService} from "../services/MessageService";

interface SaveTemplateModalProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    message: any;
    user: any;
    onTemplateCreated: (templateName: string) => void;
    onTemplateUpdated: (templateName: string) => void;
}

const SaveTemplateModal: React.FC<SaveTemplateModalProps> = ({ open, setOpen, message, user, onTemplateCreated, onTemplateUpdated }) => {
    const [actionRadioValue, setActionRadioValue] = useState<any>(1);
    const [templateRadioValue, setTemplateRadioValue] = useState<any>(-1);
    const [newTemplateName, setNewTemplateName] = useState<string>('');
    const [loading, setLoading] = useState(false)
    const [templates, setTemplates] = useState<any>([])

    useEffect(() => {
        refreshTemplates()
    }, []);


    function refreshTemplates() {
        MessageService.getMessageTemplates(setLoading, user).subscribe((data: any) => {
            console.log(`Got ${data.length} templates!`)
            setTemplates(data)
        }, (error: any) => {
            console.error(`Error getting templates: ${error}`)
        });
    }

    const onActionRadioChange = (event: any) => {
        setActionRadioValue(event.target.value)
        setNewTemplateName('')
    }

    const onTemplateRadioChange = (event: any) => {
        console.log(`Set templateRadioValue to ${event.target.value}`)
        setTemplateRadioValue(event.target.value)
    }

    function handleDialogClose() {
        setOpen(false);
        setNewTemplateName('')
        setActionRadioValue(1)
        setTemplateRadioValue(-1)
    }

    const handleNewTemplateNameChange = (event: any) => {
        setNewTemplateName(event.target.value)
    }

    const save = () => {
        console.log(`Clicked save with radio value: ${actionRadioValue}`)
        if (actionRadioValue == 1) {
            createTemplate()
        } else if (actionRadioValue == 2) {
            updateTemplate()
        }

    }

    function createTemplate() {
        console.log(`Creating new template with name: ${newTemplateName}`)
        setLoading(true)
        const template: any = {'user_id': user.id, 'reference_message_id': message.id, 'template_name': newTemplateName}
        MessageService.createTemplate(JSON.stringify(template), setLoading, user).subscribe((data: any) => {
            console.log(`Created template! ${JSON.stringify(data, null, 2)}`)
            handleDialogClose()
            onTemplateCreated(newTemplateName)
        }, (error: any) => {
            console.error(`Error creating template: ${error}`)
            handleDialogClose()
        });
    }

    function updateTemplate() {
        const chosenTemplate = templates[templateRadioValue]
        console.log(`Updating template: ${chosenTemplate['template_name']}`)
        setLoading(true)
        chosenTemplate['reference_message_id'] = message.id
        MessageService.updateTemplate(JSON.stringify(chosenTemplate), setLoading, user).subscribe((data: any) => {
            console.log(`Updated template! ${JSON.stringify(data, null, 2)}`)
            handleDialogClose()
            onTemplateUpdated(chosenTemplate['template_name'])
        }, (error: any) => {
            console.error(`Error updating template: ${error}`)
            handleDialogClose()
        });
    }

    return (
        <Modal onCancel={handleDialogClose} open={open} title={'Save Template'} centered={true}
               footer={[
                   <Button type={'primary'} size={'middle'} onClick={save}
                           disabled={(actionRadioValue == 1 && newTemplateName.length == 0) || (actionRadioValue == 2 && templateRadioValue < 0)} loading={loading}>
                       Save
                   </Button>
               ]}
        >
            <Row gutter={[18, 18]}>
                <Col xs={24}>
                    <Box sx={{paddingTop: 2, height: '100%'}}>
                        <Radio.Group onChange={onActionRadioChange} value={actionRadioValue}>
                            <Space direction={'horizontal'}>
                                <Radio value={1}>New Template</Radio>
                                <Radio value={2}>Update Existing</Radio>
                            </Space>
                        </Radio.Group>
                    </Box>
                </Col>
                <Col xs={24} style={{paddingTop: '8px', paddingBottom: '8px'}}>
                    {actionRadioValue == 1 &&
                        <Input
                            placeholder="Template Name ex 'Client Cold Outreach'"
                            value={newTemplateName}
                            onChange={handleNewTemplateNameChange}
                        />
                    }
                    {actionRadioValue == 2 &&
                        <Radio.Group style={{width: '100%'}} onChange={onTemplateRadioChange} value={templateRadioValue}>
                            <Space direction={'vertical'} style={{width: '100%'}}>
                                <div
                                    id="scrollableDiv"
                                    style={{
                                        maxHeight: 200,
                                        overflow: 'auto',
                                    }}
                                >
                                    <List
                                        size="small"
                                        dataSource={templates}
                                        renderItem={(item: any, index: number) =>
                                            <List.Item>
                                                <Radio value={index}>{item['template_name']}</Radio>
                                            </List.Item>}
                                    />
                                </div>
                            </Space>
                        </Radio.Group>
                    }
                </Col>
            </Row>
        </Modal>
    );
};

export default SaveTemplateModal;