import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import {createFilterOptions} from "@mui/material";

interface EmailInputProps {
    onChange: (emails: string[]) => void;
}

const filter = createFilterOptions<string>();

const EmailInput: React.FC<EmailInputProps> = ({ onChange }) => {
    const [emails, setEmails] = React.useState<string[]>([]);

    const handleEmailsChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
        setEmails(newValue);
        onChange(newValue);
    };

    return (
        <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={emails}
            onChange={handleEmailsChange}
            filterOptions={(options: any, params: any) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                    filtered.push(params.inputValue);
                }
                return filtered;
            }}
            renderInput={(params) => (
                <TextField {...params} label="Email Addresses" fullWidth />
            )}
        />
    );
};

export default EmailInput;