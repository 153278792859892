import {
    Box,
    Button, Card,
    CardContent, Grid, Typography
} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import * as React from "react";
import TeamTable from "./TeamTable";


const TeamScreen = (props: any) => {
    const location = useLocation();
    const { state } = location
    const [team, setTeam] = useState<any>(state.team)

    useEffect(() => {
        console.log(`Company confirmed: ${props.user.company?.confirmed ?? false}`)
    })

    return (
        <Card>
            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '800'}}>
                <CardContent sx={{flex: '1 0 auto', padding: 8, marginBottom: 8}}>
                    <Grid container>
                        <Grid item xs={12} sx={{marginTop: 3}}>
                            <TeamTable user={props.user} team={team}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Box>
        </Card>
    )
}

export default TeamScreen;