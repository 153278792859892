import React, {useEffect, useState} from 'react';
import {Col, Row} from "antd/lib";
import {Input, Modal, Radio, Button, Select, Typography, Slider} from "antd";
import IntentList from "./IntentList";
import {LoadingOutlined} from "@ant-design/icons";
import {useLocation, useNavigate} from "react-router-dom";
import "./AddTaskMessageModal.module.css";
import {MessageService} from "../services/MessageService";
import {ChatGPTService} from "../services/ChatGPTService";

const {Option} = Select;

interface AddMessageTaskModal {
    open: boolean;
    setOpen: (val: boolean) => void;
    user: any;
    onTaskAdded: (task: any) => void;
}

const AddMessageTaskModal: React.FC<AddMessageTaskModal> = ({ open, setOpen, user, onTaskAdded }) => {
    const [loading, setLoading] = useState(false)
    const location = useLocation();
    const {state} = location;
    const navigate = useNavigate();
    const [format, setFormat] = useState("LI_CONNECTION_REQUEST");
    const [intents, setIntents] = useState<any>([]);
    const [voice, setVoice] = useState("Business Casual");
    const [length, setLength] = useState("Any");
    const [useCalendar, setUseCalendar] = useState(false);
    const [templates, setTemplates] = useState<any>([]);
    const [templateOptions, setTemplateOptions] = useState<any>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
    const [clearTemplateSelect, setClearTemplateSelect] = useState(false);
    const [clearIntentsList, setClearIntentsList] = useState(false);
    const [messageConstraints, setMessageConstraints] = useState<any>({
        format: format,
        intents: intents,
        voice: voice,
        length: length,
        useCalendar: useCalendar,
        messages: [],
    });

    function handleDialogClose() {
        setOpen(false);
    }

    useEffect(() => {
        console.log(`Intents: ${JSON.stringify(intents)}`);
    }, [intents]);

    useEffect(() => {
        refreshTemplates()
    }, [])

    useEffect(() => {
        refreshTemplateOptions()
    }, [templates])

    useEffect(() => {
        console.log(`clearTemplateSelect: ${clearTemplateSelect}`)
        if (clearTemplateSelect) {
            setSelectedTemplate(null)
        }
    }, [clearTemplateSelect])


    function refreshTemplates() {
        MessageService.getMessageTemplates(setLoading, user).subscribe((data: any) => {
            console.log(`Got ${data.length} templates!`)
            setTemplates(data)
        }, (error: any) => {
            console.error(`Error getting templates: ${error}`)
        });
    }

    function refreshTemplateOptions() {
        const tmp: any = []
        if (templates.length > 0) {
            templates.forEach((t: any, index: number) => {
                tmp.push(<Option value={index}>{t['template_name']}</Option>)
            });
            setTemplateOptions(tmp);
        }
    }

    const handleClearIntentsList = (val: boolean) => {
        console.log('clearIntentsList updated from/to:', clearIntentsList, val);
        setClearIntentsList(!val);
        setClearIntentsList(val);
    };

    const handleTemplateSelect = (index: any) => {
        if (0 <= index < templates.length) {
            setSelectedTemplate(templates[index])
            setClearTemplateSelect(false)
            handleClearIntentsList(true);
            console.log(`Chose template ${templates[index]['template_name']}`)
        } else {
            console.log(`Unable to get template for index ${index}`)
        }
    };

    const handleFormatChange = (value: string) => {
        setFormat(value);
    };

    const handleVoiceChange = (value: number) => {
        setVoice(voiceOptions[value]);
    };

    const handleLengthChange = (value: number) => {
        setLength(lengthOptions[value]);
    };

    const voiceTooltipFormatter: any = (value: number) => voiceOptions[value];
    const lengthTooltipFormatter: any = (value: number) => lengthTooltips[value];

    const onIntentsChange = (passedIntents: any) => {
        console.log(`Setting intents: ${JSON.stringify(passedIntents)}`);
        setIntents(passedIntents);
        if (passedIntents.length > 0) {
            setSelectedTemplate(null)
            setClearTemplateSelect(true)
            setClearIntentsList(false)
        } else {
            setClearTemplateSelect(false)
            setClearIntentsList(true)
        }
    };

    // function goToMessage(message: any) {
    //     navigate(`/contacts/${contact.id}/message`, {state: {message: message}});
    // }

    function getMessage() {
        setLoading(true);
        messageConstraints["format"] = format;
        messageConstraints["intents"] = JSON.stringify(intents);
        messageConstraints["template"] = selectedTemplate?.id
        messageConstraints["voice"] = voice;
        messageConstraints["length"] = length;
        messageConstraints["useCalendar"] = useCalendar;
        setMessageConstraints(messageConstraints);
        console.log(`messageConstraints: ${JSON.stringify(messageConstraints)}`);
        ChatGPTService.getMessage(JSON.stringify(messageConstraints), setLoading, user).subscribe(
            (data: any) => {
                console.log(`Message: ${JSON.stringify(data)}`);
                window.heap.track("Message", data);
                messageConstraints["messages"].push(data);
                setMessageConstraints(messageConstraints);
                // goToMessage(data);
            },
            (error: any) => {
                console.error(`Error fetching message: ${error}`);
            }
        );
    }

    const voiceOptions: any = {
        0: 'Casual',
        50: 'Business Casual',
        100: 'Professional'
    };

    const lengthOptions: any = {
        0: 'Any',
        10: 'Short',
        50: 'Medium',
        100: 'Long'
    };

    const lengthTooltips: any = {
        0: 'Auto',
        10: '~30s read',
        50: '1-2m read',
        100: '2m+ read'
    }

    return (
        <Modal onCancel={handleDialogClose} open={open} title={'Add Message Task'} centered={true}
               footer={[
                   <Button type={'primary'} size={'middle'} onClick={handleDialogClose}
                           disabled={false} loading={loading}>
                       Add Task
                   </Button>
               ]}
        >
            <div style={{display: "flex", flexDirection: "column", width: "100%", height: "800"}}>
                <div style={{flex: "1 0 auto", padding: "16px"}}>
                    <Row gutter={[16, 16]}>
                        <Col xs={12}>
                            <Row>
                                <div style={{width: "100%"}}>
                                    <Select
                                        style={{width: "100%"}}
                                        value={format}
                                        onChange={handleFormatChange}
                                        placeholder="Format"
                                    >
                                        <Option value={"EMAIL"}>Email</Option>
                                        <Option value={"LI_CONNECTION_REQUEST"}>LinkedIn Connection Request</Option>
                                        <Option value={"LI_MESSAGE"}>LinkedIn Message</Option>
                                    </Select>
                                </div>
                            </Row>
                            <Row style={{marginTop: "16px"}}>
                                <div style={{
                                    padding: '16px',
                                    width: '100%',
                                    borderRadius: '5px',
                                    borderColor: '#f0f0f0',
                                    borderStyle: 'solid',
                                    borderWidth: '1px'
                                }}>
                                    <Typography.Text style={{color: 'rgba(0, 0, 0, 0.6)', fontWeight: 400, fontSize: '0.75rem', lineHeight: '1.4375em', letterSpacing: '0.00938em'}}>Message Voice</Typography.Text>
                                    <div style={{
                                        width: '100%',
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginTop: '8px'
                                    }}>
                                        <div style={{width: "85%"}}>
                                            <Slider
                                                id={"voice-slider"}
                                                defaultValue={50}
                                                marks={voiceOptions}
                                                step={25}
                                                onChange={handleVoiceChange}
                                                tooltip={{formatter: null}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            {format != 'LI_CONNECTION_REQUEST' &&
                                <Row style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "16px"
                                }}>
                                    <div style={{
                                        padding: '16px',
                                        width: '100%',
                                        borderRadius: '5px',
                                        borderColor: '#f0f0f0',
                                        borderStyle: 'solid',
                                        borderWidth: '1px'
                                    }}>
                                        <Typography.Text style={{color: 'rgba(0, 0, 0, 0.6)', fontWeight: 400, fontSize: '0.75rem', lineHeight: '1.4375em', letterSpacing: '0.00938em'}}>Message Length</Typography.Text>
                                        <div style={{
                                            width: '100%',
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginTop: '32px'
                                        }}>
                                            <div style={{width: "85%"}}>
                                                <Slider
                                                    id={"length-slider"}
                                                    defaultValue={0}
                                                    marks={lengthOptions}
                                                    step={25}
                                                    onChange={handleLengthChange}
                                                    tooltip={{formatter: lengthTooltipFormatter, open: true}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            }
                        </Col>
                        <Col xs={12}>
                            {templateOptions?.length > 0 &&
                                <div style={{marginBottom: '16px'}}>
                                    <Row style={{flexShrink: 0}}>
                                        <div style={{width: "100%"}}>
                                            <Select
                                                id='templates-select'
                                                style={{width: "100%"}}
                                                placeholder="Select Template"
                                                onSelect={handleTemplateSelect}
                                                value={clearTemplateSelect ? undefined : selectedTemplate?.template_name}
                                            >
                                                {templateOptions}
                                            </Select>
                                        </div>
                                    </Row>
                                    <Row style={{marginTop: '16px', display: 'flex', justifyContent: 'center'}}>
                                        <Typography>OR</Typography>
                                    </Row>
                                </div>
                            }
                            <Row>
                                <div style={{width: "100%"}}>
                                    <IntentList refIntentsContainer={null} refPredefinedSelect={null} onIntentsChange={onIntentsChange} format={format} clearIntentsList={clearIntentsList} />
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "16px"}}>
                        {(intents.length > 0 || selectedTemplate) && (
                            <div style={{width: "100%"}}>
                                <Button
                                    size={"small"}
                                    type={"primary"}
                                    style={{float: "right"}}
                                    onClick={getMessage}
                                    loading={loading}
                                    icon={loading ? <LoadingOutlined/> : null}>
                                    Run
                                </Button>
                            </div>
                        )}
                    </Row>
                </div>
            </div>
        </Modal>
    );
};

export default AddMessageTaskModal;