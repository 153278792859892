import * as React from 'react';
import {
    Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, OutlinedInput
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {ChangeEvent, useEffect, useState} from "react";
import HeadCell from "../../interfaces/HeadCell";
import EnhancedTable from "../../common/EnhancedTable";
import ColumnProps from "../../interfaces/ColumnProps";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import {CompanyService} from "../../services/CompanyService";
import {TeamService} from "../../services/TeamService";


const TeamsTable = (props: any) => {
    const navigate = useNavigate();
    const [teams, setTeams] = useState<any>([])
    const [rows, setRows] = useState<any>([])
    const [loadingTeams, setLoadingTeams] = useState(true)
    const [newTeamName, setNewTeamName] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false)
    const didMount = React.useRef(false)

    const headCells: readonly HeadCell[] = [
        {
            id: 'team_name',
            numeric: false,
            disablePadding: false,
            label: 'Team Name',
        },
        {
            id: 'member_count',
            numeric: true,
            disablePadding: false,
            label: '# Members',
        },
        {
            id: 'date_created',
            numeric: false,
            disablePadding: false,
            label: 'Date Created',
        },
        {
            id: 'created_by_user',
            numeric: false,
            disablePadding: false,
            label: 'Created By',
        },
        {
            id: 'team_status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
        },
    ];

    const columnPropsList: any = [
        {
            key: 'team_name',
            align: 'left',
            padding: 'normal'
        },
        {
            key: 'member_count',
            align: 'right',
            padding: 'normal'
        },
        {
            key: 'date_created',
            align: 'left',
            padding: 'normal',
            type: 'date'
        },
        {
            key: 'created_by_user',
            sub_key: 'display_name',
            align: 'left',
            padding: 'normal'
        },
        {
            key: 'team_status',
            align: 'left',
            padding: 'normal'
        },
    ]

    useEffect(() => {
        if (!didMount.current && props.user && props.user.company) {
            didMount.current = true;

            refreshTeams()
        }
        refreshTeamsTable()
    }, [props, teams])

    function refreshTeams() {
        setLoadingTeams(true)
        CompanyService.getTeams(props.user.company.id, setLoadingTeams, props.user).subscribe((data: any) => {
            console.log(`Received ${data.length} teams: \n${JSON.stringify(data, null, 2)}`)
            setTeams(data)
        }, (error: any) => {
            console.log(`Problem receiving teams: ${error}`)
        })
    }

    function refreshTeamsTable() {
        console.log(`Refreshing teams table`)
        const tmp: any = []
        teams.forEach((team: any, index: number) => {
            tmp.push({
                key: team.id,
                obj: team
            })
        })
        setRows(tmp)
    }

    function showNewTeamDialog() {
        setDialogOpen(true);
    }

    function handleDialogClose() {
        setDialogOpen(false);
        setNewTeamName('');
    }

    const handleNewTeamNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewTeamName(event.target.value);
    }

    function createNewTeam() {
        console.log(`Creating new team with name "${newTeamName}"`)
        setLoadingTeams(true)
        setDialogOpen(false)
        const team = {'team_name': newTeamName, 'created_by_user_id': props.user.id, 'company_id': props.user.company.id, 'team_status': 'ACTIVE'}
        TeamService.createNewCompany(team, loadingTeams, props.user).subscribe((data: any) => {
            setNewTeamName('')
            console.log(`Created team! ${JSON.stringify(data)}`)
            refreshTeams()
        }, (error: any) => {
            console.log(`Problem creating new team: ${error}`)
        })

    }

    const handleClick = (event: React.MouseEvent<unknown>, team: any) => {
        console.log(`Row clicked: ${team.id}`)
        navigate(`/teams/${team.id}`, {'state': {team: team}})
    };

    const actionButtonElement = (
        <Tooltip title="Add Team">
            <IconButton onClick={showNewTeamDialog}>
                <AddIcon />
            </IconButton>
        </Tooltip>
    )

    return (
        <Box>
            {loadingTeams &&
                <Box sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 2
                }}>
                    <CircularProgress/>
                </Box>
            }
            {!loadingTeams &&
                <EnhancedTable headCells={headCells} rows={rows} title={'Teams'} defaultSort={'name'}
                               columnPropsList={columnPropsList} handleClick={handleClick}
                               actionButtonElement={actionButtonElement}/>
            }
            <Dialog onClose={handleDialogClose} open={dialogOpen}>
                <DialogTitle>New Team</DialogTitle>
                <DialogContent>
                    <Grid xs={12}>
                        <Box sx={{paddingTop: 2, height: '100%'}}>
                            <FormControl fullWidth sx={{height: '100%', width: 400}}>
                                <InputLabel htmlFor="component-outlined">New Team Name</InputLabel>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={newTeamName}
                                    label="New Team Name"
                                    placeholder={'Enter your new team name'}
                                    onChange={handleNewTeamNameChange}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid xs={12}>
                        {newTeamName.length >= 2 &&
                            <Button size={'small'} variant={'contained'}
                                    sx={{float: 'right', marginTop: 2}} onClick={createNewTeam}>
                                Create
                            </Button>
                        }
                    </Grid>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default TeamsTable;
