import GeneralService from "./GeneralService";
import { Component } from "react";

export class TeamService extends Component {

    public static createNewCompany(team: any, loadingHandler: any, user: any) {
        return GeneralService.post(`/team/new`, JSON.stringify(team), loadingHandler, JSON, user);
    }

    public static getTeamMembers(teamId: number, loadingHandler: any, user: any) {
        return GeneralService.get(`/team/${teamId}/members`, loadingHandler, JSON, user);
    }

}