import GeneralService from "./GeneralService";
import { Component } from "react";

export class CompanyService extends Component {

    public static confirmCompany(companyId: number, loadingHandler: any, user: any) {
        return GeneralService.put(`/company/confirm/${companyId}`, JSON.stringify({'foo': 'bar'}), loadingHandler, JSON, user);
    }

    public static getTeams(companyId: number, loadingHandler: any, user: any) {
        return GeneralService.get(`/company/${companyId}/teams`, loadingHandler, JSON, user);
    }

}