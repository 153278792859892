import React, {useEffect, useState} from 'react';
import {Link, matchPath, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Avatar, Breadcrumb, Button, Col, Dropdown, Menu, Row, Typography, Layout as AntLayout} from 'antd';
import {MenuOutlined, UserOutlined} from '@ant-design/icons';
import AuthScreen from "./routes/auth/AuthScreen";
import UserScreen from "./routes/user/UserScreen";
import TeamsScreen from "./routes/teams/TeamsScreen";
import TeamScreen from "./routes/team/TeamScreen";
import NewOrgScreen from "./routes/new_org/NewOrgScreen";
import ContactsScreen from "./routes/contacts/ContactsScreen";
import NewContactScreen from "./routes/new_contact/NewContactScreen";
import ContactScreen from "./routes/contact/ContactScreen";
import NewCommScreen from "./routes/new_comm/NewCommScreen";
import MessageScreen from "./routes/message/MessageScreen";
import theme from "./assets/theme";

const {Item: MenuItem} = Menu;
const {Title} = Typography;
const {Header, Content} = AntLayout;


const Layout = (props: any) => {

    const routes = [
        {
            key: 'Home',
            exact: true,
            path: '/',
            component: Layout
        },
        {
            key: 'Auth',
            exact: true,
            path: '/auth',
            component: AuthScreen
        },
        {
            key: 'User',
            exact: true,
            path: '/user',
            component: UserScreen
        },
        {
            key: 'Teams',
            exact: true,
            path: '/teams',
            component: TeamsScreen
        },
        {
            key: 'Team',
            exact: true,
            path: '/teams/:id',
            component: TeamScreen
        },
        {
            key: 'New Organization',
            exact: true,
            path: '/new_org',
            component: NewOrgScreen
        },
        {
            key: 'Contacts',
            exact: true,
            path: '/contacts',
            component: ContactsScreen
        },
        {
            key: 'New Contact',
            exact: true,
            path: '/contacts/new',
            component: NewContactScreen
        },
        {
            key: 'Contact',
            exact: true,
            path: '/contacts/:id',
            component: ContactScreen
        },
        {
            key: 'New Communication',
            exact: true,
            path: '/contacts/:id/new',
            component: NewCommScreen
        },
        {
            key: 'Message',
            exact: true,
            path: '/contacts/:id/message',
            component: MessageScreen
        },
    ]
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [currentRoute, setCurrentRoute] = useState('')
    const [links, setLinks] = useState<any>([])

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const currentRoute: any = routes.find(
            route => matchPath(route.path, location.pathname)
        )
        setCurrentRoute(currentRoute.key);
        const paths: any = getPaths(currentRoute)
        const currentRoutes: [any] = getRoutesFromPaths(paths, location.pathname)
        let tmp: any = []
        currentRoutes.forEach((route: any, index) => {
            let pushThis = null;
            if (index != 0) {
                tmp.push(
                    <Typography color={'text.primary'} key={index} style={{paddingLeft: '6px', paddingRight: '6px'}}>
                        /
                    </Typography>
                )
            }
            if (route.path != currentRoute.path) {
                pushThis =
                    <Link to={route.path} key={index} style={{color: 'inherit', textDecoration: 'none'}}>
                        {route.key}
                    </Link>
            } else {
                pushThis =
                    <Typography color={'text.primary'} key={index}>
                        {route.key}
                    </Typography>
            }
            tmp.push(pushThis)
        });
        setLinks(tmp);
    }, [location, props.user]);

    function getPaths(currentRoute: any) {
        const separated: any = currentRoute.path.split('/')
        let aggregated: any = []
        separated.forEach((pathSegment: string) => {
            let last = aggregated.length > 0 ? aggregated[aggregated.length - 1] : '';
            aggregated.push(last.length > 1 ? `${last}/${pathSegment}` : `/${pathSegment}`);
        });
        return Array.from(new Set(aggregated).values());
    }

    function getRoutesFromPaths(paths: [string], rawPath: string) {
        let rs: any = []
        paths.forEach((path) => {
            let route: any = routes.find(
                r => matchPath(r.path, path)
            )
            if (route != null) {
                const routeMended = route
                routeMended.path = getPathSegment(rawPath, route.path);
                rs.push(routeMended)
            }
        })
        return rs
    }

    function getPathSegment(fullCurrentPath: string, routePathSegment: string) {
        if (routePathSegment.indexOf(':') >= 0) {
            const pieces = routePathSegment.split('/')
            const currentPieces = fullCurrentPath.split('/')
            const currentPiecesTrunc = currentPieces.slice(0, pieces.length)
            return currentPiecesTrunc.join('/')
        }
        return routePathSegment
    }

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        console.log(`Logging out!`)
        props.onUserLogout();
        handleClose();
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const menu = (
        <Menu>
            {props.user && (
                <div>
                    <Link to="/user" style={{color: 'inherit', textDecoration: 'none'}}>
                        <MenuItem>Profile</MenuItem>
                    </Link>
                    <Link to="/teams" style={{color: 'inherit', textDecoration: 'none'}}>
                        <MenuItem>Teams</MenuItem>
                    </Link>
                    <Link to="/contacts" style={{color: 'inherit', textDecoration: 'none'}}>
                        <MenuItem>Contacts</MenuItem>
                    </Link>
                    <Link to="/auth" style={{color: 'inherit', textDecoration: 'none'}}>
                        <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
                    </Link>
                </div>
            )}
            {!props.user && (
                <Link to="/auth" style={{color: 'inherit', textDecoration: 'none'}}>
                    <MenuItem>Sign In</MenuItem>
                </Link>
            )}
        </Menu>
    );

    return (
        <AntLayout style={{backgroundColor: theme.background}}>
            <Header style={{backgroundColor: '#fff', padding: 0, display: 'flex', alignItems: 'center'}}>
                <Row align="middle" style={{width: '100%'}}>
                    <Col flex="50px">
                        <Button
                            icon={<MenuOutlined/>}
                            style={{border: 'none', marginLeft: '16px'}}
                            onClick={() => console.log('Menu button clicked')}
                        />
                    </Col>
                    <Col flex="auto">
                        <Title level={4} style={{color: theme.primary, marginLeft: '16px', marginTop: 0, marginBottom: 0}}>
                            conscribe.ai
                        </Title>
                    </Col>
                    <Col flex="150px" style={{textAlign: 'right', paddingRight: '16px'}}>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <Button
                                icon={
                                    props.user && props.user.profile_pic ? (
                                        <Avatar src={props.user.profile_pic}/>
                                    ) : (
                                        <UserOutlined/>
                                    )
                                }
                                style={{border: 'none', backgroundColor: 'transparent', color: 'white'}}
                            />
                        </Dropdown>
                    </Col>
                </Row>
            </Header>
            <Breadcrumb style={{margin: '16px'}}>{links}</Breadcrumb>
            <Content style={{padding: '0 50px', backgroundColor: theme.background}}>
                <Outlet/>
            </Content>
        </AntLayout>
    );
};

export default Layout;