import React, { ChangeEvent, useEffect, useState } from "react";
import {Card, List, Input, Button, Typography, Divider, Select, Row} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
const {Option} = Select;


interface IntentListProps {
    onIntentsChange: (intents: string[]) => void;
    format: string;
    clearIntentsList: boolean;
    refPredefinedSelect: any;
    refIntentsContainer: any;
}

const IntentList: React.FC<IntentListProps> = (props) => {
    const [newIntent, setNewIntent] = useState("");
    const [intents, setIntents] = useState<string[]>([]);
    const [predefinedIntentOptionsList, setPredefinedIntentOptionsList] = useState<any>([])
    const [predefinedIntent, setPredefinedIntent] = useState<any>(null)
    const predefinedIntents: any = {
        "COLD_OUTREACH_CLIENT": ['Lead with a good hook to engage the recipient', 'Express interest in the recipient and what they do', 'Find commonalities between the sender and recipient', 'Introduce the sender and what they do', 'Schedule a call to discuss the recipient\'s personal financial goals'],
        "COLD_OUTREACH_PARTNER": ['Lead with a good hook to engage the recipient', 'Express interest in the recipient and what they do', 'Find commonalities between the sender and recipient', 'Introduce the sender and what they do', 'Schedule a call to discuss the possible partnerships'],
        "MEETING_REQUEST": ['Reconnect with the recipient', 'Ask how the recipient has been and express interest in how they are doing personally', 'Request a time to meet'],
        "MEETING_SUMMARY": ['Thank the recipient for their time', 'Summarize previous meeting', 'Describe action items']
    }
    const predefinedIntentOptions: any = {
        "LI_CONNECTION_REQUEST": [{value: 'COLD_OUTREACH_CLIENT', name: 'Cold Outreach - Client'}, {value: 'COLD_OUTREACH_PARTNER', name: 'Cold Outreach - Partnership'}],
        'EMAIL': [{value: 'COLD_OUTREACH_CLIENT', name: 'Cold Outreach - Client'}, {value: 'COLD_OUTREACH_PARTNER', name: 'Cold Outreach - Partnership'}, {value: 'MEETING_REQUEST', name: 'Meeting Request'}, {value: 'MEETING_SUMMARY', name: 'Meeting Summary'}],
        'LI_MESSAGE': [{value: 'COLD_OUTREACH_CLIENT', name: 'Cold Outreach - Client'}, {value: 'COLD_OUTREACH_PARTNER', name: 'Cold Outreach - Partnership'}, {value: 'MEETING_REQUEST', name: 'Meeting Request'}],
    }

    useEffect(() => {
        console.log(`useEffect intents, props`)
        refreshIntentsList();
        refreshPredefinedIntentOptions()
    }, [intents, props.format]);

    useEffect(() => {
        console.log(`useEffect clearIntentsList: ${props.clearIntentsList}`)
        if (props.clearIntentsList) {
            setPredefinedIntent(null)
            setIntents([])
            props.onIntentsChange([])
        }
    }, [props.clearIntentsList])

    const handleNewIntentChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewIntent(event.target.value);
    };

    const addNewIntent = () => {
        if (newIntent.length <= 5) return;
        setIntents([...intents, newIntent]);
        setNewIntent("");
        props.onIntentsChange(intents);
    };

    function deleteIntent(index: number) {
        const updatedIntents = intents.filter((_, idx) => idx !== index);
        setIntents(updatedIntents);
        props.onIntentsChange(updatedIntents);
    }

    function handleNewIntentKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            addNewIntent();
        }
    }

    function refreshIntentsList() {
        props.onIntentsChange(intents);
    }

    const handlePredefinedIntentSelect = (value: string) => {
        setPredefinedIntent(value)
        if (predefinedIntents[value]) {
            setIntents([...predefinedIntents[value]]);
        }
    };

    function refreshPredefinedIntentOptions() {
        const intentOptions = predefinedIntentOptions[props.format]
        const tmp: any = []
        if (intentOptions.length > 0) {
            intentOptions.forEach((o: any) => {
                tmp.push(<Option value={o.value}>{o.name}</Option>)
            });
            setPredefinedIntentOptionsList(tmp);
        }
    }


    return (
        <div style={{ height: "100%", display: 'flex', flexDirection: 'column' }}>
            <Row style={{flexShrink: 0}}>
                <div style={{width: "100%"}} ref={props.refPredefinedSelect}>
                    <Select
                        id='predefined-intents-select'
                        style={{width: "100%"}}
                        placeholder="Select Predefined Message Intent"
                        onSelect={handlePredefinedIntentSelect}
                        value={props.clearIntentsList ? undefined : predefinedIntent}
                    >
                        {predefinedIntentOptionsList}
                    </Select>
                </div>
            </Row>
            <Row style={{width: '100%', flexGrow: 1, marginTop: '16px'}}>
                <Card ref={props.refIntentsContainer} style={{ height: "100%", width: '100%', borderRadius: "5px"}} bodyStyle={{height: 'inherit'}}>
                    <div style={{display: "flex", alignItems: "center", width: "100%" }}>
                        <Input
                            style={{ flexGrow: 1 }}
                            placeholder="Add a new intent..."
                            value={newIntent}
                            onChange={handleNewIntentChange}
                            onKeyDown={handleNewIntentKeyPress}
                        />
                        <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={addNewIntent}
                        ></Button>
                    </div>
                    {intents.length === 0 && (
                        <List style={{ width: "100%", height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography.Text>No Intents Added</Typography.Text>
                        </List>
                    )}
                    {intents.length > 0 && (
                        <List style={{ width: "100%" }}>
                            {intents.map((intent, index) => (
                                <React.Fragment key={index}>
                                    <List.Item
                                        actions={[
                                            <Button
                                                type="text"
                                                icon={<DeleteOutlined />}
                                                onClick={() => deleteIntent(index)}
                                            />,
                                        ]}
                                    >
                                        <List.Item.Meta title={intent} />
                                    </List.Item>
                                </React.Fragment>
                            ))}
                        </List>
                    )}
                </Card>
            </Row>
        </div>
    );
};

export default IntentList;
