// NewContactScreen.tsx
import {Card, Input, Button, Row, Col, Space, Spin, TourProps, Tour} from "antd";
import React, {ChangeEvent, useRef, useState} from "react";
import {ContactService} from "../../services/ContactService";
import {useNavigate} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import {Avatar, List, Skeleton, Typography} from "antd/lib";
import Link from "antd/lib/typography/Link";
import {IconButton} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import {ScrapeService} from "../../services/ScrapeService";
import styles from './NewContactScreen.module.css';
import AddMessageTaskModal from "../../common/AddMessageTaskModal";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import config from "../../config.json";

const NewContactScreen = (props: any) => {
    const [linkedin, setLinkedin] = useState("");
    const [fullName, setFullName] = useState("");
    const [company, setCompany] = useState("");
    const [location, setLocation] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false)
    const [useSearch, setUseSearch] = useState(true);
    const [contactURLs, setContactURLs] = useState<any>([])
    const [contactInput, setContactInput] = useState<any>({
        linkedin: linkedin,
    });
    const [addTaskDialogOpen, setAddTaskDialogOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    // These are for demo
    const {demo} = config
    const refInputs = useRef(null);
    const refAlternative = useRef(null);
    const refCreateContactsButton = useRef(null);
    const [openTour, setOpenTour] = useState(demo)
    const tourSteps: TourProps['steps'] = [
        {
            title: 'Let\'s Import Your Contacts',
            description: 'Search for your message recipients and add them on this page.',
            target: null
        },
        {
            title: 'Search',
            description: 'Search for your contact by name, company, and location here.',
            target: () => refInputs.current,
            placement: 'bottom'
        },
        {
            title: 'Drop a URL',
            description: 'Alternatively, if you have the LinkedIn url for your contact handy, you can click on this and paste it in the input field.',
            target: () => refAlternative.current,
            placement: 'right'
        },
        {
            title: 'Create',
            description: 'When you have your list, click here to create your new contacts!',
            target: () => refCreateContactsButton.current,
            placement: 'left'
        }
    ]

    const handleLinkedInChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLinkedin(event.target.value);
    };

    const handleFullNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFullName(event.target.value);
    };

    const handleCompanyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCompany(event.target.value);
    };

    const handleLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLocation(event.target.value);
    };

    function searchContact() {
        setSearchLoading(true)
        const payload = {name: fullName, company: company, location: location}
        ScrapeService.search(JSON.stringify(payload), setSearchLoading, props.user).subscribe((data: any) => {
                const url = data['url']
                console.log(`Received URL: ${url}`)
                setFullName('')
                setCompany('')
                setLocation('')
                if (url != null) {
                    addContactURL(url);
                } else {
                    toast.error(`Not Found: ${payload.name} not found`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                }
            }, (error: any) => {
                setSearchLoading(false)
                console.error(`Error searching for name/company/location: ${fullName}/${company}/${location}\n${error}`);
            }
        );
    }

    function addContactURLFromInput() {
        const tmp = linkedin
        addContactURL(tmp)
        setLinkedin('')
    }

    function addContactURL(url: string) {
        contactURLs.push({url: url})
        setContactURLs(contactURLs)
        scrapeLinkedIn(url)
    }

    function scrapeLinkedIn(url: string) {
        const payload = {'linkedin_url': url, type: 'profile'}
        ScrapeService.scrapeLinkedIn(JSON.stringify(payload), null, props.user).subscribe((data: any) => {
                associateLinkedIn(url, data);
            }, (error: any) => {
                console.error(`Error scraping LinkedIn for url: ${url}`);
                associateError(url, error)
            }
        );
    }

    function associateLinkedIn(url: string, data: any) {
        const updatedContactURLs = contactURLs.map((item: any) => item.url == url ? {...item, body: data} : item);

        setContactURLs(updatedContactURLs)
    }

    function associateError(url: string, error: string) {
        const updatedContactURLs = contactURLs.map((item: any) => item.url == url ? {...item, error: error} : item);

        setContactURLs(updatedContactURLs)
    }

    function handleURLInputKeyPress(event: React.KeyboardEvent) {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevents adding a new line in the TextArea
            addContactURLFromInput();
        }
    }

    function handleSearchInputKeyPress(event: React.KeyboardEvent) {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevents adding a new line in the TextArea
            searchContact();
        }
    }

    function createNewContacts() {
        setLoading(true);
        const tmp: any = {}
        const cleanContacts: any = []
        contactURLs.forEach((contact: any) => {
            if (contact?.body) {
                cleanContacts.push(contact)
            }
        })
        tmp['contacts'] = cleanContacts
        console.log(`processInfo: ${JSON.stringify(tmp, null, 2)}`);
        ContactService.createContacts(JSON.stringify(tmp), setLoading, props.user).subscribe(
            (data: any) => {
                // Returns a list of contacts, just go to the first one
                console.log(`Received ${data.length} contacts!`)
                const firstContact = data[0]
                navigate(`/contacts/${firstContact["id"]}`, {state: {contact: firstContact}});
            },
            (error: any) => {
                console.error(`Error creating contacts: ${error}`);
                toast.error(`There was a problem creating your contacts! Please try again soon.`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                setLoading(false)
            }
        );
    }

    function removeURL(index: number) {
        console.log(`Removing URL at index ${index} with length ${contactURLs.length}`)
        setContactURLs([...contactURLs.slice(0, index), ...contactURLs.slice(index + 1)])
    }

    function showMessageTaskDialog() {
        setAddTaskDialogOpen(true)
    }

    function onMessageTaskAdded() {

    }

    return (
        <Card style={{height: "800px"}}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Title level={5} style={{display: 'inline', marginRight: 5}}>Who would you like to reach?</Title>
                </Col>
            </Row>
            {useSearch &&
                <Row id={'use-search-container'} gutter={[16, 16]} style={{marginTop: '8px'}}>
                    <Col span={24}>
                        <div ref={refInputs} style={{width: '100%'}}>
                            <Space.Compact style={{width: "100%"}}>
                                <Input
                                    placeholder='First & last name'
                                    value={fullName}
                                    onChange={handleFullNameChange}
                                    onKeyDown={handleSearchInputKeyPress}
                                    style={{width: "40%"}}
                                />
                                <Input
                                    placeholder='Company'
                                    value={company}
                                    onChange={handleCompanyChange}
                                    onKeyDown={handleSearchInputKeyPress}
                                    style={{width: "40%"}}
                                />
                                <Input
                                    placeholder='Location (optional)'
                                    value={location}
                                    onChange={handleLocationChange}
                                    onKeyDown={handleSearchInputKeyPress}
                                    style={{width: "20%"}}
                                />
                                <Button
                                    type="primary"
                                    onClick={searchContact}
                                    loading={searchLoading}
                                    disabled={fullName.length < 5 || company.length < 3}
                                >
                                    Search
                                </Button>
                            </Space.Compact>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'start'}}>
                            <Link ref={refAlternative} onClick={() => {
                                setUseSearch(!useSearch)
                            }}>
                                Use a LinkedIn URL instead
                            </Link>
                        </div>
                    </Col>
                </Row>
            }
            {!useSearch &&
                <Row id={'use-url-container'} gutter={[16, 16]} style={{marginTop: '8px'}}>
                    <Col span={24}>
                        <Space.Compact style={{width: "100%"}}>
                            <Input
                                placeholder='LinkedIn URL'
                                value={linkedin}
                                onChange={handleLinkedInChange}
                                style={{width: "100%"}}
                                onKeyDown={handleURLInputKeyPress}
                            />
                            <Button
                                type="primary"
                                onClick={addContactURLFromInput}
                                style={{float: "right"}}
                                disabled={!linkedin.includes('linkedin.com/in/')}
                            >
                                Add
                            </Button>
                        </Space.Compact>
                    </Col>
                    <Col span={24}>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'start'}}>
                            <Link onClick={() => {
                                setUseSearch(!useSearch)
                            }}>
                                Use search instead
                            </Link>
                        </div>
                    </Col>
                </Row>
            }
            <Row gutter={[16, 16]} style={{marginTop: '24px'}}>
                <Col xs={24}>
                    <List
                        className={`list-url ${styles['list-url']}`}
                        itemLayout="horizontal"
                        dataSource={contactURLs}
                        bordered
                        style={{width: '100%'}}
                        renderItem={(item: any, index: number) => (
                            <List.Item
                                key={index}
                                actions={[<IconButton size={'small'} component={'label'}
                                                      onClick={() => removeURL(index)}>
                                    <RemoveIcon color={'error'}/>
                                </IconButton>]}
                            >
                                {!item?.error &&
                                    <Skeleton active avatar loading={!item?.body} title={false}>
                                        <List.Item.Meta
                                            avatar={<Avatar src={item?.body?.profile_pic_url}/>}
                                            title={<a href={item.url} target={'_blank'}
                                                      rel={'noopener noreferrer'}>{item?.body?.first_name} {item?.body?.last_name}</a>}
                                            description={item?.body?.occupation ?? item?.body?.headline}
                                        />
                                    </Skeleton>
                                }
                                {item?.error &&
                                    <List.Item.Meta
                                        title={'Error Fetching LinkedIn Profile'}
                                        description={<div>We encountered an error fetching the LinkedIn profile
                                            associated with the following url: <a href={item.url} target={'_blank'}
                                                                                  rel={'noopener noreferrer'}>{item.url}</a>
                                        </div>}
                                    />
                                }
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
            <Row style={{marginTop: '16px'}}>
                <Col span={24}>
                    <Button
                        block
                        onClick={showMessageTaskDialog}
                        disabled={true}>
                        Add Message Generation Task (Coming Soon!)
                    </Button>
                </Col>
            </Row>
            <Row style={{marginTop: '16px'}} justify={'end'}>
                <Col>
                    <Button
                        ref={refCreateContactsButton}
                        type="primary"
                        onClick={createNewContacts}
                        style={{float: "right"}}
                        loading={loading}
                        disabled={contactURLs.length == 0}>
                        Create Contacts
                    </Button>
                </Col>
            </Row>
            <AddMessageTaskModal open={addTaskDialogOpen} setOpen={setAddTaskDialogOpen} user={props.user}
                                 onTaskAdded={onMessageTaskAdded}/>
            <ToastContainer/>
            <Tour open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps} />
        </Card>
    );
};

export default NewContactScreen;
