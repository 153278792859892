import React, { useEffect, useState } from "react";
import { Tag, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { TagService } from "../services/TagService";

const TagsComponent = (props: any) => {
    const navigate = useNavigate();
    const [tags, setTags] = useState<any>([]);
    const [tagElements, setTagElements] = useState<any>([]);
    const didMount = React.useRef(false);

    useEffect(() => {
        if (
            !didMount.current &&
            props.entityType &&
            props.entityId &&
            props.tagType
        ) {
            didMount.current = true;

            TagService.getTags(
                props.entityType,
                props.entityId,
                props.tagType,
                null,
                props.user
            ).subscribe(
                (data: any) => {
                    console.log(`Received ${data.length} tags`);
                    setTags(data);
                },
                (error: any) => {
                    console.log(`Problem receiving tags: ${error}`);
                }
            );
        }
        refreshTagElements();
    }, [props, tags]);

    function refreshTagElements() {
        console.log(`Refreshing tag elements`);
        const tmp: any = [];
        tags.forEach((tag: any, index: number) => {
            tmp.push(
                <Tag
                    key={index}
                    color="blue"
                    closable
                    onClose={() => deleteTag(index)}
                >
                    {tag.tag_content}
                </Tag>
            );
        });
        tmp.push(
            <Tag key={2}>
                <span>Add Tag</span>
            </Tag>
        );
        setTagElements(tmp);
    }

    function deleteTag(index: number) {
        TagService.deleteTag(tags[index].id, null, props.user).subscribe(
            () => {
                console.log(`Removed tag index ${index}`);
                const tmp = tags.splice(index, 1);
                setTags(tmp);
            },
            (error: any) => {
                console.log(`Problem deleting tag: ${error}`);
            }
        );
    }

    return <div>{tagElements}</div>;
};

export default TagsComponent;