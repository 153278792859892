import {Typography} from "@mui/material";
import {Outlet} from "react-router-dom";


const ContactRoute = () => {
    return (
        <Outlet/>
    )
}

export default ContactRoute;