import { Component } from "react";
import { Observable } from "rxjs";

export class GeneralService extends Component {
    public static readonly PROD_HOST = 'api.conscribe.ai';
    public static readonly PROD_ENDPOINT = `https://${GeneralService.PROD_HOST}`;
    public static readonly options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'User-Agent': 'Mozilla/5.0',
            'Content-Type': 'application/json',
            'Accept-Charset': 'UTF-8',
            'X-Device-Id': ''
        }
    };

    static fetchHandler(path: string, options: any, loadingHandler: any, expectedType: any) {
        console.log(`[${options['method']}] ${path}`);
        return Observable.create((observer: any) => {
            const fullPath = `${GeneralService.PROD_ENDPOINT}${path}`;
            fetch(fullPath, options)
                .then(response => {
                    console.log(`response: ${JSON.stringify(response)}`)
                    if (response.ok) {
                        if (expectedType === JSON) {
                            try {
                                return response.json()
                            } catch (e) {
                                console.log(`Error getting JSON from response, getting TEXT instead`)
                                return response.text()
                            }
                        } else {
                            return response.text()
                        }
                    } else {
                        return Promise.reject(response)
                    }
                }) // or text() or blob() etc.
                .then(data => {
                    observer.next(data);
                    observer.complete();
                    if (loadingHandler != null) loadingHandler(false);
                })
                .catch(response => {
                    console.log(response.status, response.statusText)
                    response.text().then((data: string) => {
                        observer.error(data)
                    })
                });
        });
    }

    public static get(path: string, loadingHandler = null, expectedType: any = JSON, user: any = null) {
        let getOptions: any = structuredClone(GeneralService.options);
        getOptions['headers']['Authorization'] = user ? user.id : null;
        return GeneralService.fetchHandler(path, getOptions, loadingHandler, expectedType);
    }

    public static post(path: string, body: string, loadingHandler = null, expectedType: any = JSON, user: any = null) {
        let postOptions: any = structuredClone(GeneralService.options);
        postOptions['method'] = 'POST';
        postOptions['body'] = body;
        postOptions['headers']['Authorization'] = user ? user.id : null;
        console.log(`Options: ${JSON.stringify(postOptions)}`)
        return this.fetchHandler(path, postOptions, loadingHandler, expectedType);
    }

    public static put(path: string, body: string, loadingHandler = null, expectedType: any = JSON, user: any = null) {
        let putOptions: any = structuredClone(GeneralService.options);
        putOptions['method'] = 'PUT';
        putOptions['body'] = body;
        putOptions['headers']['Authorization'] = user ? user.id : null;
        return this.fetchHandler(path, putOptions, loadingHandler, expectedType);
    }

    public static delete(path: string, loadingHandler = null, expectedType: any = JSON, user: any = null) {
        let deleteOptions: any = structuredClone(GeneralService.options);
        deleteOptions['method'] = 'DELETE'
        deleteOptions['headers']['Authorization'] = user ? user.id : null;
        return this.fetchHandler(path, deleteOptions, loadingHandler, expectedType);
    }

    private static formatAuth(userId: number) {
        return `Basic ${btoa(userId+":password")}`
    }

}

export default GeneralService;