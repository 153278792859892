import colors from './colors';

export interface Theme {
    primary: string;
    secondary: string;
    success: string;
    error: string;
    warning: string;
    info: string;
    light: string;
    dark: string;
    text: string;
    background: string;
}

const theme: Theme = {
    ...colors,
};

export default theme;