import GeneralService from "./GeneralService";
import { Component } from "react";

export class MessageService extends Component {

    public static getMessagesByContact(contactId: number, loadingHandler: any, user: any) {
        return GeneralService.get(`/message/v2/contact/${contactId}`, loadingHandler, JSON, user)
    }

    public static getMessageVersions(messageId: number, loadingHandler: any, user: any) {
        return GeneralService.get(`/message/v2/${messageId}/versions`, loadingHandler, JSON, user)
    }

    public static addFeedback(body: string, loadingHandler: any, user: any) {
        return GeneralService.post('/message/v2/add_feedback', body, loadingHandler, JSON, user);
    }

    public static completeDraft(body: string, loadingHandler: any, user: any) {
        return GeneralService.post('/message/v2/complete_draft', body, loadingHandler, JSON, user);
    }

    public static createTemplate(body: string, loadingHandler: any, user: any) {
        return GeneralService.post('/message/v2/template', body, loadingHandler, JSON, user);
    }

    public static updateTemplate(body: string, loadingHandler: any, user: any) {
        return GeneralService.put('/message/v2/template', body, loadingHandler, JSON, user);
    }

    public static getMessageTemplates(loadingHandler: any, user: any) {
        return GeneralService.get(`/message/v2/templates`, loadingHandler, JSON, user)
    }

    public static getMessageGenCapableStatus(contactId: number, contactCompanyId: number, loadingHandler: any, user: any) {
        return GeneralService.get(`/message/v3/gen_capability_status/${contactId}/${user.company_id}/${contactCompanyId}`, loadingHandler, JSON, user)
    }

}