import GoogleAuth from "../../common/GoogleAuth";
import {UserService} from "../../services/UserService";
import jwt_decode from 'jwt-decode';
import {
    Box,
    Button,
    Card,
    CardContent, CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle, FormControl,
    Grid,
    IconButton, InputAdornment, InputLabel, OutlinedInput
} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useGoogleLogin} from "@react-oauth/google";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import {Image, Row} from "antd";
import {Typography} from "antd/lib";
import config from "../../config.json";


const AuthScreen = (props: any) => {

    const navigate = useNavigate();
    const didMount = React.useRef(false)
    const location = useLocation();
    const {demo} = config
    const [linkedin, setLinkedin] = useState<any>('')
    const [userAuthInfo, setUserAuthInfo] = useState<any>(null)
    const [signupDialogOpen, setSignupDialogOpen] = useState<any>(false)
    const [loadingSignup, setLoadingSignup] = useState(false)

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            if (location.pathname != '/') {
                navigate('/')
                console.log('Navigating to root')
            }
        }
    }, [])

    const handleGoogleAuthSuccessSignIn = (payload: any) => {
        console.log('User authenticated with Google!', JSON.stringify(payload));
        UserService.authenticate(payload, null).subscribe((data: any) => {
            console.log('Authenticated as ', JSON.stringify(data));
            props.onUserLogin(data);
            navigate('/')
        }, (error: any) => {
            console.log(`Error authenticating: ${error}`)
            toast.error(error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        })
    };

    const handleGoogleAuthSuccessSignUp = (payload: any) => {
        console.log('User authenticated with Google!', JSON.stringify(payload));
        setUserAuthInfo(payload)
        openSignupDialog()
    };

    function openSignupDialog() {
        setSignupDialogOpen(true);
    }

    function handleDialogClose() {
        setSignupDialogOpen(false);
        setLinkedin('');
    }

    const handleLinkedInChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLinkedin(event.target.value);
    }

    const login = useGoogleLogin({onSuccess: tokenResponse => handleGoogleAuthSuccessSignIn(tokenResponse)});

    const signup = useGoogleLogin({onSuccess: tokenResponse => handleGoogleAuthSuccessSignUp(tokenResponse)});

    function signupUser() {
        const tmp: any = structuredClone(userAuthInfo)
        tmp['linkedin'] = linkedin
        handleDialogClose()
        setLoadingSignup(true)
        console.log(`Signing up with: ${JSON.stringify(tmp)}`)
        UserService.signup(tmp, setLoadingSignup).subscribe((data: any) => {
            console.log('Signed up new user: ', JSON.stringify(data));
            props.onUserLogin(data);
            navigate('/')
        }, (error: any) => {
            console.log(`Error signing up new user: ${error}`)
            setLoadingSignup(false)
            toast.error(error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        })
    }

    return (
        <Grid container
              sx={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', minHeight: '100vh'}}>
            <Grid item xs={6}>
                <Card>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <CardContent sx={{flex: '1 0 auto', marginTop: '20%', marginBottom: '20%'}}>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '8px',
                                paddingTop: '24px'
                            }}>
                                <Image
                                    width={200}
                                    src={'logo192.png'}
                                    preview={false}
                                />
                            </div>
                            {demo &&
                                <div>
                                    <Row style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                        <Typography.Title level={5}>Thanks for trying out the Conscribe
                                            Demo!</Typography.Title>
                                    </Row>
                                    <Row style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                        <Typography.Title level={5}>You are Brad, a financial advisor looking to grow
                                            his client base.</Typography.Title>
                                    </Row>
                                    <Row style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                        <Typography.Title level={5}>Click below to get started.</Typography.Title>
                                    </Row>
                                </div>
                            }
                            {!loadingSignup &&
                                <div>
                                    <Grid container sx={{marginTop: demo ? '24px' : '48px'}}>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                width: '100%',
                                                display: 'flex',
                                                alignContent: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                <GoogleAuth onSuccess={handleGoogleAuthSuccessSignIn}/>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {!demo &&
                                        <div>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    alignContent: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: 3
                                                }}>
                                                    <Typography>or</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    alignContent: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: 3
                                                }}>
                                                    <Button onClick={() => signup()} variant={'text'} color={'primary'}>Try
                                                        for
                                                        free</Button>
                                                </Box>
                                            </Grid>
                                        </div>
                                    }
                                </div>
                            }
                            {loadingSignup &&
                                <Box sx={{
                                    height: '100%',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: 2,
                                    marginTop: '8px'
                                }}>
                                    <CircularProgress/>
                                </Box>
                            }
                        </CardContent>
                    </Box>
                </Card>
            </Grid>
            <Dialog onClose={handleDialogClose} open={signupDialogOpen}>
                <DialogTitle>Provide Your LinkedIn</DialogTitle>
                <DialogContent>
                    <Grid xs={12}>
                        <Box sx={{paddingTop: 2, height: '100%'}}>
                            <FormControl fullWidth sx={{height: '100%', width: 400}}>
                                <InputLabel htmlFor="component-outlined">LinkedIn</InputLabel>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={linkedin}
                                    label="LinkedIn"
                                    placeholder={'https://www.linkedin.com/in/joe-someone'}
                                    onChange={handleLinkedInChange}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid xs={12}>
                        {linkedin.length > 2 &&
                            <Button size={'small'} variant={'contained'}
                                    sx={{float: 'right', marginTop: 2}} onClick={signupUser}>
                                Sign Up
                            </Button>
                        }
                    </Grid>
                </DialogContent>
            </Dialog>
            <ToastContainer/>
        </Grid>
    )
}

export default AuthScreen;