import React from 'react';
import {GoogleLogin, useGoogleLogin} from "@react-oauth/google";
import GoogleButton from "react-google-button";
import config from '../config.json'

type Props = {
    onSuccess: (idToken: any) => void;
};

const GoogleAuth: React.FC<Props> = ({ onSuccess  }) => {
    const { demo } = config;

    const login = useGoogleLogin({onSuccess: tokenResponse => onSuccess(tokenResponse)});
    const demoLogin = () => {
        const response: any = {'access_token': 'demo'}
        onSuccess(response)
    }

    return (
        <GoogleButton onClick={() => demo ? demoLogin() : login()}></GoogleButton>
    );
};

export default GoogleAuth;