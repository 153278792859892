import GeneralService from "./GeneralService";
import { Component } from "react";

export class UserService extends Component {

    public static authenticate(userObject: any, loadingHandler: any) {
        const body = JSON.stringify(userObject)
        return GeneralService.put('/user/v2/authenticate', body, loadingHandler, JSON, null);
    }

    public static signup(userObject: any, loadingHandler: any) {
        const body = JSON.stringify(userObject)
        return GeneralService.post('/user/v3/signup', body, loadingHandler, JSON, null);
    }

}