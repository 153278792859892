import GeneralService from "./GeneralService";
import { Component } from "react";

export class ScrapeService extends Component {

    public static scrapeLinkedIn(body: string, loadingHandler: any, user: any) {
        return GeneralService.post('/scrape/linkedin', body, loadingHandler, JSON, user);
    }

    public static search(body: string, loadingHandler: any, user: any) {
        return GeneralService.post('/scrape/search', body, loadingHandler, JSON, user);
    }


}